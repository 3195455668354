<template>
  <div>
    <base-title title="Dashboard" />

    <base-empty
      title="Você ainda não possui nenhum Dashboard."
      message="Dashboards personalizados para o seu negócio."
    >
      <template slot="image">
        <i class="far fa-house-blank" />
      </template>
    </base-empty>
  </div>
</template>

<script>
import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';

export default {
  components: {
    BaseTitle,
    BaseEmpty,
  },
};
</script>

<style scoped></style>
