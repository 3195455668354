export default {
  methods: {
    getDay(date) {
      const day = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
      });
      return Number(day);
    },
    getMonth(date) {
      const month = date.toLocaleDateString('pt-BR', {
        month: 'long',
      });
      return `${month.charAt(0).toUpperCase() + month.slice(1)}`;
    },
    getYear(date) {
      const year = date.toLocaleDateString('pt-BR', {
        year: 'numeric',
      });
      return Number(year);
    },
  },
};
