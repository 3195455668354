export const baseEvents = {
    props: ['value'],
    computed: {
        inputListeners: function () {
          var vm = this
          return Object.assign({},

            // Eventos
            this.$listeners,
            {
              // Isso garante que o componente funcione com o v-model e MASCARADO
              input: function (event) {
                
                if((vm.mask || vm.moneyMask) && !vm.masked) {
                  vm.$emit('input', vm.unmask(event.target.value, vm.mask || vm.moneyMask))
                  return
                }
                
                if (event.target.type === 'checkbox') {
                  let value = (event.target.value == 'true' || event.target.value == 'on')
                  event.target.value = !value
                }
                
                vm.$emit('input', event.target.value, event)
              },

              change: function (event) {
                vm.$emit('change', event.target.value, event)
              }
            }
          )
        }
    },
}