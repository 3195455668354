<template>
  <div>
    <div class="users-grid">
      <div v-for="(oUser, iUser) in filteredUsers()" :key="iUser">
        <users-card
          :showDelete="aUsers.length > 1"
          @deleteUser="deleteUser"
          @editUser="openEditUser"
          :oUser="oUser"
          :currentUser="currentUser"
        />
      </div>
    </div>

    <base-drawer
      ref="drawer"
      :open="open"
      :close="close"
      :title="
        selectedUser.id ? StringLanguage.editUser : StringLanguage.linkUser
      "
    >
      <users-form
        v-if="$refs.drawer && !!$refs.drawer.status"
        ref="user"
        @editUser="editUser"
        @userDeleted="userDeleted"
        :selectedUser="selectedUser"
        :userError="userError"
        @setUserError="setUserError"
      />

      <template v-slot:footer>
        <div class="d-flex">
          <base-button
            v-if="selectedUser.id"
            class="mr_15"
            design="four"
            @click.prevent="deleteUser()"
            :title="StringLanguage.removeUser"
          >
            <i class="far fa-trash-alt" />
          </base-button>
          <base-button
            @click="saveUser()"
            :text="
              selectedUser.id ? StringLanguage.save : StringLanguage.create
            "
            class="w-100"
          />
        </div>
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UsersCard from '@/containers/Users/UsersCard';
import UsersForm from '@/containers/Users/UsersForm';
import { UsersAPI } from '@/api/Users';

import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';

export default {
  data() {
    return {
      open: false,
      selectedUser: {},
      close: false,
      aUsers: [],
      userError: null,
    };
  },
  created() {
    this.loadUsers();
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.account.oUser,
    }),
  },
  methods: {
    loadUsers() {
      return UsersAPI.users().then((response) => {
        this.aUsers = response.getData([]);
        return this.aUsers;
      });
    },
    filteredUsers() {
      const hideUsers = 'suppletspoke';

      return this.aUsers.filter((user) => !hideUsers.includes(user.username));
    },
    editUser() {
      this.loadUsers();
      this.close = !this.close;
      this.selectedUser = {};
    },
    openAddUser() {
      this.selectedUser = {};

      this.open = !this.open;
    },
    setUserError(prop) {
      this.userError = prop;
    },
    openEditUser(oUser) {
      this.selectedUser = { ...oUser, currentGroup: oUser.group };
      this.userError = null;
      this.open = !this.open;
    },
    deleteUser() {
      this.$refs.user.del();
    },
    saveUser() {
      this.loadUsers();
      this.$refs.user.soe();
    },
    userDeleted() {
      this.close = !this.close;
      this.loadUsers();
    },
  },
  components: {
    UsersCard,
    UsersForm,
    BaseButton,
    BaseDrawer,
  },
};
</script>
<style scoped>
.users-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .users-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
</style>
