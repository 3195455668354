<template>
  <base-drawer
    :title="
      drawerType === 'create'
        ? 'Configurar programa de cashback'
        : 'Editar programa de cashback'
    "
    :open="open"
    :close="close"
    @onClose="closeDrawer"
    :goBack="step > 1 && !loading"
    :back="step > 1 && !loading"
    @onBack="prevStep()"
  >
    <div v-if="loading" class="d-flex align-center justify-center h-100">
      <base-loading />
    </div>

    <base-steps v-else :steps="3" :currentStep="step">
      <values-step
        v-if="step === 1"
        :cashback="cashback"
        @changed="applyValues"
      />
      <receive-step
        v-if="step === 2"
        :showReceiveTags="showReceiveTags"
        :cashback="cashback"
        @changed="applyValues"
      />
      <remind-step
        v-if="step === 3"
        :showRemindTags="showRemindTags"
        :cashback="cashback"
        @changed="applyValues"
      />
    </base-steps>

    <template slot="footer" v-if="!loading">
      <base-button
        class="w-100"
        @click="nextStep()"
        v-if="
          step < 3 ||
          (step === 3 && showCashbackRemind === 'yes' && !showRemindTags)
        "
        :disabled="hasErrors"
      >
        Próximo
      </base-button>
      <base-button
        class="w-100"
        v-if="
          (step === 3 && showCashbackRemind === 'no') ||
          (step === 3 && showRemindTags)
        "
        @click="save"
        :disabled="hasErrors"
      >
        {{ drawerType === 'create' ? 'Salvar programa' : 'Salvar edições' }}
      </base-button>
    </template>
  </base-drawer>
</template>

<script>
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';
import BaseLoading from '@/components/Loading';
import BaseSteps from '@/components/Steps';

import ValuesStep from '@/containers/Cashback/Form/ValuesStep.vue';
import ReceiveStep from '@/containers/Cashback/Form/ReceiveStep.vue';
import RemindStep from '@/containers/Cashback/Form/RemindStep.vue';

import { CashbackProgramAPI } from '@/api/CashbackProgramAPI';

export default {
  components: {
    BaseButton,
    BaseDrawer,
    BaseLoading,
    BaseSteps,
    ValuesStep,
    ReceiveStep,
    RemindStep,
  },
  props: {
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    drawerType: { type: String, default: 'create' },
    program: {
      type: Object,
      default: () => ({
        percentage: null,
        expiration_days: null,
        max_gain: null,
        min_order: null,
        message: { type: '', text: '' },
        expiration_message: { type: '', text: '' },
      }),
    },
  },
  data() {
    return {
      loading: false,
      step: 1,
      hasErrors: true,
      showCashbackReceive: 'no',
      showCashbackRemind: 'no',
      showReceiveTags: false,
      showRemindTags: false,
      testPhone: { ddd: '', number: '' },
      cashback: JSON.parse(JSON.stringify(this.program)),
    };
  },
  created() {
    this.hasErrors = this.validateStep();
  },
  methods: {
    applyValues(cashbackProgram, showCashbackReceive, showCashbackRemind) {
      this.cashback = { ...this.cashback, ...cashbackProgram };

      if (showCashbackReceive !== null) {
        if (showCashbackReceive) this.showCashbackReceive = showCashbackReceive;
      }

      if (showCashbackRemind) this.showCashbackRemind = showCashbackRemind;

      this.hasErrors = this.validateStep();
    },
    closeDrawer() {
      this.$emit('onClose');
    },
    nextStep() {
      if (
        this.step === 3 &&
        this.showCashbackRemind === 'yes' &&
        this.cashback.expiration_message.type &&
        !this.showRemindTags
      ) {
        this.showRemindTags = true;
        return;
      }

      if (
        this.step === 2 &&
        this.showCashbackReceive === 'yes' &&
        this.cashback.message.type &&
        !this.showReceiveTags
      ) {
        this.showReceiveTags = true;
        return;
      }

      this.step += 1;
      this.hasErrors = this.validateStep();
    },
    prevStep() {
      if (
        this.step === 3 &&
        this.showCashbackRemind === 'yes' &&
        this.showRemindTags
      ) {
        this.showRemindTags = false;
        return;
      }

      if (
        this.step === 2 &&
        this.showCashbackReceive === 'yes' &&
        this.showReceiveTags
      ) {
        this.showReceiveTags = false;
        return;
      }

      this.step -= 1;
      this.hasErrors = this.validateStep();
    },
    validateStep() {
      switch (this.step) {
        case 1: {
          if (
            +this.cashback.percentage >= 1 &&
            +this.cashback.max_gain > 0 &&
            +this.cashback.expiration_days > 0
          )
            return false;
          break;
        }
        case 2: {
          const { message } = this.cashback;

          if (this.showCashbackReceive === 'yes' && message.type) {
            if (this.showReceiveTags) {
              if (
                message.text.indexOf('<link>') > -1 &&
                message.text.indexOf('<valorrecebido>') > -1
              ) {
                return false;
              }
            }

            if (!this.showReceiveTags) {
              return false;
            }
          }

          if (this.showCashbackReceive === 'no') {
            return false;
          }

          break;
        }
        case 3: {
          const { expiration_message } = this.cashback;

          if (
            this.showCashbackRemind === 'yes' &&
            expiration_message.type &&
            expiration_message.remind_hour
          ) {
            if (this.showRemindTags) {
              if (
                expiration_message.text.indexOf('<link>') > -1 &&
                expiration_message.text.indexOf('<valorrecebido>') > -1
              ) {
                return false;
              }
            }

            if (!this.showRemindTags) {
              return false;
            }
          }

          if (this.showCashbackRemind === 'no') {
            return false;
          }

          break;
        }
      }

      return true;
    },
    async save() {
      this.loading = true;

      const {
        percentage,
        expiration_days,
        max_gain,
        min_order,
        message,
        expiration_message,
      } = this.cashback;

      const response = await CashbackProgramAPI.putProgram({
        percentage: Number(percentage),
        min_order: Number(min_order || 0),
        max_gain: Number(max_gain),
        expiration_days: Number(expiration_days),
        message: !message
          ? null
          : {
              type: `${message.type}`.toUpperCase(),
              text: message.text,
            },
        expiration_message: !expiration_message
          ? null
          : {
              type: `${expiration_message.type}`.toUpperCase(),
              text: expiration_message.text,
              remind_days_before: Number(expiration_message.remind_days_before),
              remind_hour: expiration_message.remind_hour,
            },
      });

      this.loading = false;
      if (response.getCode(500) != 200) {
        alert(response.getErrors(['Ocorreu um erro ao salvar o programa!'])[0]);
        return;
      }

      this.closeDrawer();
      this.step = 1;
      this.$emit('save', this.cashback);
      return;
    },
  },
  watch: {
    showReceiveTags: {
      handler() {
        this.hasErrors = this.validateStep();
      },
    },
    showRemindTags: {
      handler() {
        this.hasErrors = this.validateStep();
      },
    },
  },
};
</script>

<style scoped>
.use-of-tags {
  background: rgba(46, 52, 56, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.use-of-tags-1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.use-of-tags-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #aaaaab;
  background: rgba(46, 52, 56, 0.75);
  border-radius: 10px;
  padding: 20px 50px;
  margin-top: 15px;
}

.tag {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6161ff;
}

.count-characters {
  background: rgba(53, 61, 66, 0.33);
  border-radius: 10px;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.count-characters .number {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(177, 177, 178, 0.9);
}

.count-characters .type {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.85);
}

.count-characters .type:nth-child(2) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid rgba(111, 118, 126, 0.35);
}

.preview {
  background: #222629;
  border-radius: 15px;
  padding: 15px;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #aaaaaa;
  min-height: 90px;
  word-break: break-all;
  white-space: break-spaces;
}

.enter-phone {
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.75);
  margin-bottom: 20px;
}

.fire-test-message {
  background: rgba(46, 52, 56, 0.3);
  border-radius: 10px;
  padding: 20px;
}
</style>
