<template>
  <div>
    <base-title
      title="Benefícios de ativação"
      subtitle="Eventos"
      link="eventos"
      @addAction="openDrawer()"
      :showButtonAdd="isMobile"
    >
      <base-button
        v-if="benefits.length > 0"
        text="Adicionar benefício"
        @click="openDrawer()"
      />
    </base-title>

    <base-empty
      v-if="benefits.length === 0"
      title="Você ainda não possui benefícios."
      message="Através desta ferramenta, será possível criar seus benefícios."
      buttonText="Adicionar benefício"
      @onClick="openDrawer()"
    >
      <template slot="image">
        <i class="fal fa-gift" />
      </template>
    </base-empty>

    <div class="benefits" v-else>
      <div class="benefit" v-for="(benefit, i) in benefits" :key="i">
        <div class="header">
          <div class="icon">
            <i class="far fa-gift" />
          </div>

          <p class="title">
            {{ benefit.type === 'cashback' ? 'Cashback' : benefit.code }}
          </p>
          <p v-if="benefit.type !== 'cashback'">
            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
          </p>
          <strong v-if="benefit.code">9Z3</strong>

          <i class="far fa-chevron-right" />
        </div>

        <div class="infos">
          <div class="info">
            <p>Benefício</p>
            <span v-if="benefit.type === 'freeDelivery'">Entrega grátis</span>
            <span v-else>{{
              benefit.type === 'discountMoney'
                ? benefit.value
                : `${
                    benefit.type === 'cashback'
                      ? benefit.cashback
                      : benefit.value
                  }%`
            }}</span>
          </div>

          <div class="info">
            <p>Expiração</p>
            <span>02/05/22</span>
          </div>
        </div>
      </div>
    </div>

    <base-drawer
      :title="`${getTitle()}`"
      :open="drawer.open"
      :close="drawer.close"
      :goBack="step === 2 && !showLoading"
      :back="step === 2 && !showLoading"
      @onBack="jumpToStep(1)"
      @onClose="closeDrawer()"
    >
      <base-card v-if="step === 1">
        <base-label text="Benefício imediato" />

        <div class="grid-options">
          <div
            class="option"
            :class="{ active: selectedBenefit === 'cashback' }"
            @click="selectedBenefit = 'cashback'"
          >
            <i
              class="far fa-hand-holding-dollar"
              v-if="selectedBenefit !== 'cashback'"
            />
            <i class="far fa-check" v-if="selectedBenefit === 'cashback'" />
            <p>Cashback</p>
          </div>
        </div>
      </base-card>

      <base-card v-if="step === 1">
        <base-label text="Benefícios futuros" />

        <div class="grid-options">
          <div
            class="option"
            :class="{ active: selectedBenefit === 'discountMoney' }"
            @click="selectedBenefit = 'discountMoney'"
          >
            <i
              class="far fa-sack-dollar"
              v-if="selectedBenefit !== 'discountMoney'"
            />
            <i
              class="far fa-check"
              v-if="selectedBenefit === 'discountMoney'"
            />
            <p>Desconto em R$</p>
          </div>

          <div
            class="option"
            :class="{ active: selectedBenefit === 'discountPercent' }"
            @click="selectedBenefit = 'discountPercent'"
          >
            <i
              class="far fa-percent"
              v-if="selectedBenefit !== 'discountPercent'"
            />
            <i
              class="far fa-check"
              v-if="selectedBenefit === 'discountPercent'"
            />
            <p>Desconto em %</p>
          </div>

          <div
            class="option"
            :class="{ active: selectedBenefit === 'freeDelivery' }"
            @click="selectedBenefit = 'freeDelivery'"
          >
            <i
              class="far fa-motorcycle"
              v-if="selectedBenefit !== 'freeDelivery'"
            />
            <i class="far fa-check" v-if="selectedBenefit === 'freeDelivery'" />
            <p>Entrega grátis</p>
          </div>
        </div>
      </base-card>

      <div v-if="step === 2" :class="{ loading: showLoading }">
        <div v-if="!showLoading">
          <base-card
            v-if="
              selectedBenefit === 'discountMoney' ||
              selectedBenefit === 'discountPercent' ||
              selectedBenefit === 'freeDelivery'
            "
          >
            <base-label text="Insira o código do benefício" />
            <base-input
              label="Código do benefício"
              class="mb_0"
              v-model="benefit.code"
            />
          </base-card>

          <div v-if="selectedBenefit === 'cashback'">
            <base-card>
              <base-label
                text="Determine o valor do cashback e o pedido mínimo"
              />
              <div class="d-flex">
                <base-input
                  label="Valor cashback"
                  class="w-100 mb_0"
                  v-model="benefit.cashback"
                  :money-mask="StringLanguage.percentConfigs"
                />
                <base-input
                  label="Pedido mínimo"
                  class="w-100 ml_15 mb_0"
                  v-model="benefit.minOrder"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>

            <base-card>
              <base-label text="Defina o limite de ganho deste benefício" />
              <base-input
                placeholder="R$"
                class="mb_0"
                v-model="benefit.gainLimit"
                :money-mask="StringLanguage.currencyConfigs"
              />
            </base-card>
          </div>

          <div
            v-if="
              selectedBenefit === 'discountMoney' ||
              selectedBenefit === 'discountPercent'
            "
          >
            <base-card>
              <base-label
                text="Determine o valor do benefício e o pedido mínimo"
              />

              <div class="d-flex">
                <base-input
                  label="Valor"
                  class="w-100 mb_0"
                  v-model="benefit.value"
                  :money-mask="StringLanguage.currencyConfigs"
                />
                <base-input
                  label="Pedido mínimo"
                  class="w-100 ml_15 mb_0"
                  v-model="benefit.minOrder"
                  :money-mask="StringLanguage.currencyConfigs"
                />
              </div>
            </base-card>
          </div>

          <div v-if="selectedBenefit === 'freeDelivery'">
            <base-card>
              <base-label text="Determine o valor do pedido mínimo" />
              <base-input
                label="Pedido mínimo"
                class="mb_0"
                v-model="benefit.minOrder"
                :money-mask="StringLanguage.currencyConfigs"
              />
            </base-card>
          </div>

          <base-card>
            <base-label text="Defina a expiração do benefício" />
            <base-input
              placeholder="--- dias"
              v-model="benefit.expDays"
              :thousandSeparator="false"
              :moneyMask="StringLanguage.timeConfigs.days"
            />

            <base-message>
              A expiração deste benefício inicia a partir do momento que o
              usuário o recebe.
            </base-message>
          </base-card>
        </div>

        <base-loading v-if="showLoading" />
      </div>

      <div v-if="step === 3">
        <base-card>
          <div class="success">
            <i class="far fa-check" />
            <p>Agora você pode voltar para os benefícios.</p>
          </div>
        </base-card>
      </div>

      <template slot="footer">
        <base-button
          v-if="step === 1"
          class="w-100"
          :disabled="!selectedBenefit"
          @click="jumpToStep(2)"
        >
          Próximo
        </base-button>

        <base-button
          v-if="step === 2 && !showLoading"
          class="w-100"
          :disabled="!selectedBenefit"
          @click="createBenefit()"
        >
          Criar benefício
        </base-button>

        <base-button v-if="step === 3" class="w-100" @click="closeDrawer()">
          Voltar para benefícios
        </base-button>
      </template>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseTitle from '@/components/Title';
import BaseButton from '@/components/Button';
import BaseEmpty from '@/components/Empty';
import BaseDrawer from '@/components/Drawer';
import BaseLabel from '@/components/Label';
import BaseCard from '@/components/Card';
import BaseMessage from '@/components/Message';
import BaseInput from '@/components/Input';
import BaseLoading from '@/components/Loading';

export default {
  components: {
    BaseTitle,
    BaseButton,
    BaseEmpty,
    BaseDrawer,
    BaseLabel,
    BaseCard,
    BaseMessage,
    BaseInput,
    BaseLoading,
  },
  data() {
    return {
      selectedTab: 0,
      selectedBenefit: null,
      step: 1,
      showLoading: false,
      benefit: {
        code: null,
        value: null,
        cashback: null,
        minOrder: null,
        expDays: null,
      },
      benefits: [],
      drawer: {
        open: false,
        close: false,
      },
    };
  },
  methods: {
    createBenefit() {
      this.showLoading = true;

      let type = null;

      switch (this.selectedBenefit) {
        case 'cashback':
          type = 'cashback';
          break;
        case 'discountMoney':
          type = 'discountMoney';
          break;
        case 'discountPercent':
          type = 'discountPercent';
          break;
        case 'freeDelivery':
          type = 'freeDelivery';
          break;
      }

      const benefit = {
        type,
        code: this.benefit.code,
        value: this.benefit.value,
        cashback: this.benefit.cashback,
        minOrder: this.benefit.minOrder,
        expDays: this.benefit.expDays,
      };

      setTimeout(() => {
        this.benefits.push(benefit);

        this.selectedBenefit = null;
        this.showLoading = false;
        this.step = 3;
      }, 3000);
    },
    getTitle() {
      if (this.step === 1) {
        return `Adicionar benefício`;
      }

      if (this.step === 2) {
        let title = '';

        switch (this.selectedBenefit) {
          case 'cashback':
            title = '- Desconto em cashback';
            break;
          case 'discountMoney':
            title = '- Desconto em R$';
            break;
          case 'discountPercent':
            title = '- Desconto em %';
            break;
          case 'freeDelivery':
            title = '- Entrega grátis';
            break;
        }

        return `Adicionar benefício ${title}`;
      }

      if (this.step === 3) {
        return 'Benefício adicionado com sucesso!';
      }
    },
    jumpToStep(step) {
      this.step = step;
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = false;
    },
    closeDrawer() {
      this.drawer.open = false;
      this.drawer.close = !this.drawer.close;
      this.selectedBenefit = '';
      this.step = 1;
      this.showLoading = false;

      Object.keys(this.benefit).forEach((key) => {
        delete this.benefit[key];
      });
    },
    changeRoute(routeName, params) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      this.$router.push({ name: routeName });
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.tabs {
  margin-bottom: 40px;
}
.tabs ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}
.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

@media screen and (max-width: 991px) {
  .tabs {
    margin-bottom: 25px;
  }
}

.all-events {
  position: relative;
}

.all-events:before {
  content: '';
  height: calc(100% - 30px);
  top: 0;
  bottom: 0;
  left: 65px;
  margin: auto;
  border-left: 1px dashed rgba(111, 118, 126, 0.5);
  position: absolute;
}

.date-events {
  color: rgba(235, 235, 235, 0.55);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  font-weight: 900;
}

.all-events .event {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.all-events .time {
  background: #1e2124;
  border-radius: 15px;
  color: rgba(111, 118, 126, 1);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-right: 32.5px;
}

.all-events .details {
  background: var(--box);
  border-radius: 10px;
  padding: 15px 15px 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
}

.all-events .details:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  position: absolute;
  left: -22px;
  margin: auto;
  background: var(--default);
}

.all-events .details .type {
  background: #23282b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 30px;
  position: relative;
  height: 100%;
  position: absolute;
  border-radius: 5px 0 0 5px;
  left: 0;
  width: 245px;
}

.all-events .details .type .bar {
  transform: rotate(180deg);
  background: #32393d;
  width: 2px;
  border-radius: 10px;
  position: absolute;
  left: 10px;
  height: calc(100% - 30px);
}

.all-events .details .type .store {
  border-radius: 50%;
  border: 1px solid rgba(111, 118, 126, 0.11);
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(111, 118, 126, 0.85);
  font-size: 10px;
}

.all-events .details .type p {
  border: 1px dashed rgba(111, 118, 126, 0.2);
  padding: 5px 15px;
  border-radius: 5px;
  color: rgba(235, 235, 235, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.all-events .details .infos {
  display: flex;
  align-items: center;
  margin-left: 250px;
  padding: 0 20px;
}

.all-events .details .infos p {
  color: rgba(170, 170, 171, 0.9);
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.all-events .details .infos p.name {
  font-weight: 500;
}

.all-events .details .infos p.address {
  font-weight: bold;
  color: #b1b1b2;
}

.all-events .details .infos p.not-found {
  font-style: italic;
  color: rgba(170, 170, 171, 0.55);
}

.all-events .details .infos i {
  color: rgba(170, 170, 171, 0.9);
  font-size: 15px;
  margin-right: 10px;
}

.all-events .details .infos p:not(:last-child) {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(111, 118, 126, 0.55);
}

.all-events .details .buttons {
  display: flex;
}

.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option p {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.success {
  background: #2d3338;
  border-radius: 10px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success i {
  color: #82d997;
  font-size: 20px;
  margin-bottom: 25px;
}

.success p {
  color: rgba(204, 204, 204, 0.75);
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
}

.benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.benefits .benefit {
  background: var(--box);
  border-radius: 10px;
  padding: 10px 10px 20px;
}

.benefits .benefit .header {
  background: rgba(30, 33, 36, 0.37);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
  color: #cccccc;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Rubik', sans-serif;
}

.benefits .benefit .header .icon {
  background: rgba(30, 33, 36, 0.45);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.benefits .benefit .header .icon i {
  font-size: 12px;
  color: #6f767e;
}

.benefits .benefit .header .title {
  font-weight: 600;
}

.benefits .benefit .header strong {
  color: var(--default);
}

.benefits .benefit .header i.fa-chevron-right {
  position: absolute;
  right: 10px;
  color: #6f767e;
  font-size: 10px;
}

.benefits .benefit .infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.benefits .benefit .infos .info {
  display: flex;
  flex-direction: column;
}

.benefits .benefit .infos .info:last-child {
  align-items: flex-end;
}

.benefits .benefit .infos .info p {
  color: #cccccc;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.benefits .benefit .infos .info span {
  color: rgba(204, 204, 204, 0.65);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

@media screen and (max-width: 991px) {
  .benefits {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
