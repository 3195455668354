<template>
  <div class="form-wrapper">
    <div class="form">
      <div>
        <div class="logo">
          <a href="https://easydelivery.com.br" target="_blank">
            <img src="https://cdn.easydelivery.com.br/easy_logo_pink.svg" />
          </a>
        </div>
        <div class="form-container">
          <div v-if="loginLoading" class="login-loading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <p class="welcome">Bem - vindo</p>
          <p v-if="stepForgot === 0" class="message">
            Informe o nome de usuário para recuperar sua senha.
          </p>
          <p v-else-if="stepForgot === 1" class="message line-height">
            Acabamos de enviar um código de confirmação para
            <span class="fw-900">{{ protectedEmail }}</span
            >, basta preenche-lo abaixo.
          </p>
          <p v-else-if="stepForgot === 2" class="message">
            Agora é só preencher sua nova senha.
          </p>
          <p v-else-if="stepForgot === 4" class="message">
            Informe o email utilizado na criação da loja.
          </p>
          <!-- Forgot Pwd -->
          <div v-if="stepForgot === 0" class="form-login">
            <base-input
              :design="'one'"
              :label="'Nome de usuário'"
              v-model="user.username"
              ref="username"
              :type="'text'"
              class="mb_25 pt_0"
            />
            <div style="margin-top: -10px" class="mb_15" v-if="usernameError">
              <base-message v-if="usernameError == 'request'" design="red"
                >Ocorreu um erro ao verificar o usuário.</base-message
              >
              <base-message v-if="usernameError == 'invalid'" design="red"
                >O usuário informado é inválido.</base-message
              >
              <base-message v-if="usernameError == 'not_found'" design="red"
                >Não encontramos nenhuma conta com este usuário.</base-message
              >
              <base-message v-if="usernameError == 'not_allowed'" design="red"
                >Sua conta possuí permissão de funcionário, peça a seu superior,
                que ele altere sua senha na tela de Usuários.</base-message
              >
            </div>
            <base-button
              :text="'Confirmar'"
              class="w-100 big"
              @click="jumpStep(1)"
            />
            <div class="links">
              <p @click="$router.replace({ name: 'login' })">Voltar ao login</p>
              <div class="divisor"></div>
              <p @click="jumpStep(4)">Esqueci meu usuário</p>
            </div>
          </div>
          <!-- Se for master -->
          <div v-if="stepForgot === 1" class="form-login">
            <base-input
              :design="'one'"
              :label="'Código'"
              v-model="emailCode"
              ref="emailCode"
              type="tel"
              mask="######"
              class="mb_25 pt_0"
            />
            <div style="margin-top: -10px" class="mb_15" v-if="emailError">
              <base-message v-if="emailError == 'send'" :design="'red'"
                >Não foi possível enviar o código de verificação.</base-message
              >
              <base-message v-if="emailError == 'invalid'" design="red"
                >Código <span class="fw-900">inválido</span>!</base-message
              >
            </div>
            <base-button
              :text="'Confirmar'"
              class="w-100 big"
              @click="jumpStep(2)"
            />
            <div class="links">
              <p @click="$router.replace({ name: 'login' })">Voltar ao login</p>
              <div class="divisor"></div>
              <p v-if="emailTimer > 0" :style="emailTimer > 0 && 'opacity: .5'">
                Reenviar código ({{ secondsToMinutesString(emailTimer) }})
              </p>
              <p v-else @click="requestUsernameCode()">Reenviar código</p>
            </div>
          </div>
          <div v-if="stepForgot === 2" class="form-login">
            <base-input
              :design="'one'"
              :label="'Nova senha'"
              v-model="user.password"
              type="password"
              @blur="validatePasswords()"
              @focus="passwordError = null"
              class="mb_25 pt_0"
            />
            <base-input
              :design="'one'"
              :label="'Repetir senha'"
              v-model="user.confirm_password"
              type="password"
              @blur="validatePasswords()"
              @focus="passwordError = null"
              class="mb_25 pt_0"
            />

            <div style="margin-top: -10px" class="mb_15" v-if="passwordError">
              <base-message v-if="passwordError == 'request'" design="red"
                >Ocorreu um erro ao trocar a senha.</base-message
              >
              <base-message v-if="passwordError == 'not_found'" design="red"
                >Não encontramos nenhuma conta com este usuário.</base-message
              >
              <base-message v-if="passwordError == 'not_allowed'" design="red"
                >Alteração de senha não permitida.</base-message
              >
              <base-message v-if="passwordError == 'invalid'" design="red"
                >As senhas devem ter no mínimo 8 caracteres (letras ou
                números).</base-message
              >
              <base-message v-else-if="passwordError == 'confirm'" design="red"
                >As senhas não correspondem.</base-message
              >
            </div>
            <base-button
              :text="'Alterar'"
              class="w-100 big"
              @click="jumpStep(3)"
            />
            <div class="links">
              <p @click="$router.replace({ name: 'login' })">Voltar ao login</p>
            </div>
          </div>
          <div v-if="stepForgot === 4" class="form-login">
            <base-input
              :design="'one'"
              :label="'E-mail'"
              v-model="user.email"
              type="text"
              class="mb_25 pt_0"
            />
            <div style="margin-top: -10px" class="mb_15" v-if="userEmailError">
              <base-message v-if="userEmailError == 'invalid'" design="red"
                >O e-mail informado é inválido.</base-message
              >
              <base-message v-if="userEmailError == 'not_found'" design="red"
                >Não encontramos nenhuma conta com este usuário.</base-message
              >
              <base-message v-if="userEmailError == 'not_allowed'" design="red"
                >Não foi possível encontrar uma conta master com este e-mail.
                Caso você seja um funcionário, peça a seu superior, que ele
                altere sua senha na tela de Usuários.</base-message
              >
              <!-- Caso não encontre o email, ou encontre de não master -->
              <base-message v-if="userEmailError == 'success'" design="gold"
                >O e-mail de recuperação foi enviado com sucesso.</base-message
              >
              <!-- Apenas uma conta neste email -->
            </div>
            <base-button
              v-if="userEmailError != 'success'"
              :text="'Confirmar'"
              class="w-100 big"
              @click="jumpStep(5)"
            />
            <div class="links">
              <p @click="jumpStep(0)">Voltar para a recuperação</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/veasy/login.css';

import User from 'easyjs/lib/Schema/Account/User';
import AccountAPI from '@/api/account';
import StoreAPI from '@/api/store';
import { Http } from 'easyjs';
import { setXUserKey, setXAPIEnv, getXUserKey } from '@/plugins/account';
import Utils from '@/utils';
import { ExceptionCode } from 'easyjs/lib/Exceptions/ExceptionCode';
// UI
import BaseInput from '@/components/Input';
import BaseBox from '@/components/Box';
import BaseButton from '@/components/Button';
import BaseMessage from '@/components/Message';

const { Request } = Http;

export default {
  data() {
    return {
      loginLoading: false,
      stepForgot: 0,
      user: new User({}),
      usernameError: null,
      protectedEmail: null,
      tokenCode: null,
      emailCode: null,
      emailTimer: 0,
      emailError: null,
      passwordError: null,
      blockClick: false,
      userEmailError: null,
    };
  },
  mounted() {
    this.$refs.username.focus();
  },
  methods: {
    secondsToMinutesString(seconds) {
      const finalMinutes = (seconds - (seconds % 60)) / 60;
      const finalSeconds = seconds % 60;
      return `${`${finalMinutes}`.padStart(
        2,
        '0'
      )}:${`${finalSeconds}`.padStart(2, '0')}`;
    },
    validateEmail() {
      try {
        const { user } = this;
        user.validate(['email']);
        this.userEmailError = null;
        return true;
      } catch (err) {
        this.userEmailError = 'invalid';
        return false;
      }
    },
    async validateUser() {
      //Verificar se username é um usuário tem permissão para alterar a senha
      try {
        if (!this.user.username || this.user.username.length < 5) {
          this.usernameError = 'invalid';
          return false;
        }

        const hasPermissionResponse = await AccountAPI.hasPasswordPermission(
          this.user.username
        );
        const responseCode = hasPermissionResponse.getCode(500);
        if (responseCode != 200) {
          if (responseCode == ExceptionCode.USER_NOT_FOUND) {
            this.usernameError = 'not_found';
          } else if (responseCode == ExceptionCode.NOT_ALLOWED) {
            this.usernameError = 'not_allowed';
          } else {
            this.usernameError = 'request';
          }
          return false;
        }

        this.protectedEmail = hasPermissionResponse.getData({
          email: null,
        }).email;
        this.usernameError = null;
        return true;
      } catch (err) {
        this.usernameError = 'request';
        return false;
      }
    },
    async validateUsernameCode() {
      const response = await AccountAPI.validateUsernameCode(
        this.emailCode,
        this.user.username
      );
      const { success, token } = response.getData({
        success: false,
        token: null,
      });
      if (!success) {
        this.emailCode = null;
        this.emailError = 'invalid';
        return false;
      }

      this.tokenCode = token;
      return true;
    },
    validatePasswords() {
      const user = this.user;
      this.passwordError = null;

      try {
        user.validate(['password']);
      } catch (err) {
        this.passwordError = 'invalid';
        return false;
      }

      if (user.password != user.confirm_password) {
        this.passwordError = 'confirm';
        return false;
      }

      return true;
    },
    async jumpStep(step) {
      if (this.blockClick) {
        return false;
      }

      this.blockClick = true;

      switch (step) {
        case 1:
          //Verifica se usuário tem permissão de alteração de senha
          if (await this.validateUser()) {
            //Requisita o código de verificação de e-mail
            this.requestUsernameCode();
            this.stepForgot = step;
          }
          break;
        case 2:
          //Valida o código informado pelo usuário
          if (await this.validateUsernameCode()) {
            this.stepForgot = step;
          }
          break;
        case 3:
          //Valida as senhas informadas
          if (this.validatePasswords()) {
            //Realiza a requisição de envio
            await this.changePassword();
          }
          break;
        case 5:
          if (this.validateEmail()) {
            await this.requestUsername();
          }
          break;
        case 0:
        case 4:
          this.clearPage();
          this.stepForgot = step;
          break;
      }

      this.blockClick = false;
    },
    clearPage() {
      this.user = new User({});
      this.usernameError = null;
      this.protectedEmail = null;
      this.tokenCode = null;
      this.emailCode = null;
      this.emailTimer = 0;
      this.emailError = null;
      this.passwordError = null;
      this.blockClick = false;
      this.userEmailError = null;
    },
    async requestUsernameCode() {
      this.emailCode = null;
      const response = await AccountAPI.requestUsernameCode(this.user.username);
      if (!response.getData({ success: false }).success) {
        this.emailError = 'send';
        return false;
      }

      this.$refs.emailCode.focus();

      this.emailTimer = 60;
      const timerId = setInterval(() => {
        if (this.emailTimer > 0) {
          this.emailTimer--;
        } else {
          clearInterval(timerId);
        }
      }, 1000);

      return true;
    },
    async changePassword() {
      try {
        const response = await AccountAPI.changePassword(
          this.user.username,
          this.user.password,
          this.tokenCode
        );
        const responseCode = response.getCode(500);
        if (responseCode == 200) {
          this.$router.replace({ name: 'login' });
        } else {
          if (responseCode == ExceptionCode.USER_NOT_FOUND) {
            this.passwordError = 'not_found';
          } else if (responseCode == ExceptionCode.NOT_ALLOWED) {
            this.passwordError = 'not_allowed';
          } else {
            this.passwordError = 'request';
          }
          return false;
        }
      } catch (err) {
        this.passwordError = 'request';
        return false;
      }
      return true;
    },
    async requestUsername() {
      try {
        const response = await AccountAPI.retrieveUsername(this.user.email);
        const responseCode = response.getCode(500);
        if (responseCode == 200) {
          this.userEmailError = 'success';
          return true;
        } else {
          if (responseCode == ExceptionCode.USER_NOT_FOUND) {
            this.userEmailError = 'not_found';
          } else if (responseCode == ExceptionCode.NOT_ALLOWED) {
            this.userEmailError = 'not_allowed';
          } else {
            this.userEmailError = 'request';
          }
        }
      } catch (err) {
        this.userEmailError = 'request';
      }
      return false;
    },
  },
  components: {
    BaseInput,
    BaseBox,
    BaseButton,
    BaseMessage,
  },
};
</script>