<template>
  <div class="form-wrapper">
    <div class="form">
      <div>
        <div class="logo">
          <img src="@/assets/img/logo-login-olga.png" class="Logo Olga" />
        </div>

        <div class="form-container">
          <div v-if="loginLoading" class="login-loading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <p class="welcome animated">Bem-vindo</p>

          <form @submit.prevent class="form-login">
            <base-input
              :label="'Nome de usuário'"
              :type="'text'"
              :valid="!usernameError && !errorLogin"
              id="user"
              name="username"
              v-model="oLogin.username"
              @keyup.enter="sendLogin"
              @focus="usernameError = null"
              ref="username"
              :design="'one'"
            />
            <base-input
              :label="'Sua senha'"
              :type="'password'"
              :valid="!passwordError && !errorLogin"
              id="password"
              name="password"
              v-model="oLogin.password"
              @keyup.enter="sendLogin"
              @focus="passwordError = null"
              ref="password"
              design="one"
            />

            <div v-if="usernameError || passwordError || errorLogin">
              <base-message v-if="usernameError" design="red">
                O campo usuário é obrigatório.
              </base-message>
              <base-message v-if="passwordError" design="red">
                A senha é obrigatória.
              </base-message>
              <base-message
                v-if="errorLogin && !usernameError && !passwordError"
                design="red"
              >
                Usuário e/ou senha incorretos!
              </base-message>
            </div>

            <base-button
              :text="'Entrar'"
              class="w-100 mt_25"
              @click="sendLogin"
            />

            <div class="d-flex align-center justify-center">
              <p
                @click="showAlertForgotPassword = true"
                class="forgot-password"
              >
                Esqueceu sua senha?
              </p>
            </div>

            <base-message v-if="showAlertForgotPassword" class="mt_20">
              Entre em contato conosco para recuperar sua senha
            </base-message>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/veasy/login.css';
import { mapState } from 'vuex';
import getOS from '@/mixins/getOS.js';
import { setXUserKey } from '@/plugins/account';
import router from '@/router';
import { scrollToMe } from '@/plugins/scroll';
import AppBridge from '@/plugins/app';
import DesktopBridge from '@/plugins/desktop';

import { OAuthAPI } from '@/api/OAuth';
import { UsersAPI } from '@/api/Users';

// UI

import BaseInput from '@/components/Input';
import BaseButton from '@/components/Button';
import BaseMessage from '@/components/Message';

export default {
  mixins: [getOS],
  data() {
    return {
      loginLoading: false,
      usernameError: null,
      passwordError: null,
      environmentError: null,
      showEnvInput: false,
      oLogin: {
        password: '',
        username: '',
        environment: '',
        errorMessage: '',
      },
      errorLogin: null,
      showAlertForgotPassword: false,
    };
  },
  async beforeMount() {
    if (AppBridge.isApp()) {
      this.loginLoading = true;
      AppBridge.emit('onOpenUpdate');
      AppBridge.listen('noOpenUpdate', () => {
        this.loginLoading = false;
      });
    }
  },
  mounted() {
    if (AppBridge.isApp()) {
      AppBridge.emit('onLockOrientation');
    }

    document.onkeydown = this.keydown;

    const lastEmail = localStorage.getItem('lastLoginEmail');
    if (lastEmail && lastEmail !== 'null') {
      this.oLogin.username = localStorage.getItem('lastLoginEmail');
      return this.$refs.password.focus();
    }

    this.$refs.username.focus();
  },
  methods: {
    async sendLogin() {
      const loginIsValid = this.validateLogin();

      if (!loginIsValid) {
        return;
      }

      if (this.showEnvInput) {
        if (this.oLogin.environment === '') {
          this.environmentError = 'required';
          return false;
        }
        const self = this;
        const response = await OAuthAPI.authenticate(this.oLogin);

        if (!response._data) {
          this.loginLoading = false;
          this.oLogin.errorMessage = 'Usuário e/ou senha incorreto!';
          this.errorLogin = 'Usuário e/ou senha incorreto!';
          return false;
        }
        const result = response.getData(false);

        setXUserKey(result['x-user-key']);
        setXAPIEnv(
          Array.isArray(result['x-api-env'])
            ? result['x-api-env'][0]
            : result['x-api-env']
        );
        if (!result.permissions.includes('ENVIRONMENT_LOGIN')) {
          this.environmentError = 'forbidden';
          return false;
        }
        const config = await self.$store.dispatch('store/getConfigurations');
        const deliveryConfig = this.$store.dispatch(
          'deliveryPlatform/loadConfigurations'
        );

        return false;
      }

      const self = this;
      self.loginLoading = true;
      self.oLogin.errorMessage = '';

      const response = await OAuthAPI.authenticate({
        username: self.oLogin.username,
        password: self.oLogin.password,
      });

      const result = response.getData(false);
      if (!result) {
        self.loginLoading = false;
        self.oLogin.errorMessage = response.getErrors()[0];
        self.errorLogin = response.getErrors()[0];
        return false;
      }

      localStorage.setItem('lastLoginEmail', self.oLogin.username);
      setXUserKey(result['access_token']);

      const userResponse = await UsersAPI.me();
      const user = userResponse.getData({ id: null, name: null });

      if (DesktopBridge.isDesktop()) {
        DesktopBridge.emit('onLoginUpdate');
      }

      if (AppBridge.isApp()) {
        AppBridge.emit('onLoginUpdate');
        AppBridge.emit('onUnlockOrientation');
      }

      router.push({ name: 'dashboard' });

      return false;
    },
    validateLogin() {
      if (this.oLogin.username === '') {
        this.usernameError = 'required';
        return false;
      }

      if (this.oLogin.password === '') {
        this.passwordError = 'required';
        return false;
      }

      return true;
    },
    scrollToMe() {
      scrollToMe('body', -80);
    },
  },
  components: {
    BaseInput,
    BaseButton,
    BaseMessage,
  },
  watch: {
    updateLater(status) {
      this.loginLoading = !status;
    },
  },
  computed: {
    ...mapState({
      updateLater: (state) => state.store.updateLater,
    }),
  },
};
</script>
