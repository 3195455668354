<template>
  <div>
    <base-title title="Cashback" :date="date" @hideDate="hideDate">
      <base-button v-if="selectedProgram && !this.date" design="two" :class="isMobile ? 'reverse w-100 mb_15' : ''" @click="editProgram()" >
        Editar programa
      </base-button>

      <base-button v-if="selectedProgram  && !this.date" design="two" :class="isMobile ? 'reverse w-100 mt_15' : 'ml_15'" @click="setStatus(program.status != 'ENABLED')">
        {{program.status == 'ENABLED' ? "Parar programa" : "Retomar programa" }}
      </base-button>

      <base-button v-if="selectedProgram && showHistoryButton" design="two" :class="isMobile ? 'reverse w-100 mt_15' : 'ml_15'" @click="openDrawer('past')">
        Ver passado
      </base-button>
            
    </base-title>

    <base-empty
      v-if="!selectedProgram"
      title="Não há programa de cashback."
      message="Através dessa ferramenta, será possível fornecer cashback a seus clientes."
      buttonText="Iniciar programa"
      @onClick="openDrawer('cashback')"
    >
      <template slot="image">
        <i class="far fa-hand-holding-dollar" />
      </template>
    </base-empty>
    <div class="cashback" v-else>
      <cashback-resume :cashback="program" />
      <cashback-indicators :reports="programReports" />
    </div>

    <div v-if="drawer.cashback.canShow">
      <cashback-form
        :open="drawer.cashback.open"
        :close="drawer.cashback.close"
        :drawerType="drawer.type"
        :program="program"
        @onClose="closeDrawer('cashback')"
        @save="onSave"
      />
    </div>
    
    <past-report
      :open="drawer.past.open"
      :close="drawer.past.close"
      @onClose="closeDrawer('past')"
      @seeResults="seeResults"
      :key="`past-report-${selectedProgram}`"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import BaseLoading from '@/components/Loading';

import CashbackIndicators from '@/pages/Cashback/CashbackIndicators';
import CashbackResume from '@/pages/Cashback/CashbackResume';
import CashbackForm from '@/pages/Cashback/CashbackForm';
import PastReport from '@/pages/Cashback/PastReport';
import { CashbackProgramAPI } from '@/api/CashbackProgramAPI'

const components = {
  BaseButton,
  BaseTitle,
  BaseEmpty,
  BaseLoading,
  CashbackIndicators,
  CashbackResume,
  CashbackForm,
  PastReport
}

export default {
  components,
  data() {
    return {
      showHistoryButton: false,
      selectedProgram: null,
      program: {
        percentage: null,
        message: { type: '', text: '' },
        max_gain: null,
        min_order: null,
        expiration_days: null,
        expiration_message: { type: '', text: '' }
      },
      programReports: {},
      date: null,
      drawer: {
        type: null,
        cashback: {
          canShow: false,
          open: false,
          close: false,
        },
        past: {
          open: false,
          close: false,
        },
      },
    };
  },
  mounted() {
    this.loadCashbackProgram();
  },
  methods: {
    async loadCashbackProgram(id = null) {
      const response = await CashbackProgramAPI.getProgram(id);
      const program = response.getData(null);

      if(response.getCode(500) != 200 || !program) {
        return;
      }

      this.selectedProgram = program.id;
      this.program = program;

      
      this.showHistoryButton = id == null ? program.version_code > 1 : true;
      
      this.drawer.cashback.canShow = true;
      await this.loadCashbackProgramReports(program.id);
    },
    async loadCashbackProgramReports(id) {
      const response = await CashbackProgramAPI.getProgramReports(id);
      const reports = response.getData(null);
      if(response.getCode(500) != 200 || !reports) {
        return;
      } else {
        this.programReports = reports;  
      }
    },
    openDrawer(drawer) {
      this.drawer[drawer].canShow = true;
      this.drawer[drawer].open = !this.drawer[drawer].open;
      this.drawer.type = 'create';
    },
    closeDrawer(drawer) {
      this.drawer[drawer].open = !this.drawer[drawer].open;
      this.drawer[drawer].close = !this.drawer[drawer].close;
      this.drawer[drawer].visible = false;
    },
    async onSave(program) {
      await this.loadCashbackProgram();
    },
    async setStatus(enable) {
      return confirm(`Você tem certeza que deseja ${enable? 'retomar': 'parar'} o programa?`, async (res) => {
            if (!res) {
              return false;
            }

            const response = await CashbackProgramAPI.putStatus(
              this.selectedProgram,
              enable ? 'ENABLED': 'DISABLED'
            );

            if(response.getCode(500) != 200) {
              alert(response.getErrors(["Ocorreu um erro ao salvar o programa!"])[0]);
              return;
            }

            await this.loadCashbackProgram();
        }, enable? 'Retomar': 'Parar', 'Cancelar'
      );
    },
    editProgram() {
      confirm(
        `Ao realizar uma edição, os relatórios de seu programa serão reiniciados, mas não se preocupe pois será possível consultar resultados antigos. Deseja continuar?`,
        async (res) => {
          if (!res) {
            return false;  
          }

          this.openDrawer('cashback');
          this.drawer.type = 'edit';
        },
        'Continuar',
        'Cancelar'
      );
    },
    async seeResults(program) {
      let programId = null;
      if(program.deleted_at) {
        this.date = [
          dayjs(program.created_at).format('DD/MM/YYYY'),
          dayjs(program.deleted_at).format('DD/MM/YYYY'),
        ];
        programId = program.id;
      }

      await this.loadCashbackProgram(programId)
    },
    async hideDate() {
      this.date = null;
      await this.loadCashbackProgram(null)
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.cashback {
  display: flex;
  align-items: stretch;
}

@media screen and (max-width: 991px) {
  .cashback {
    flex-direction: column-reverse;
  }
}
</style>
