<template>
  <div class="resume-cashback">
    <div class="cashback">
      <p>Cashback de&nbsp;</p>
      <span class="value">{{ formatPercent() }}</span>
    </div>

    <div class="details">
      <div class="item">
        <p>Pedido mínimo</p>
        <span>{{ formatCurrency(cashback.min_order) }}</span>
      </div>

      <div class="item">
        <p>Expira em</p>
        <span>{{ cashback.expiration_days }} dia(s)</span>
      </div>

      <div class="item">
        <p>Limite de ganho</p>
        <span>{{ formatCurrency(cashback.max_gain) }}</span>
      </div>
    </div>

    <div class="messages">
      <p class="title">Comunicação de recebimento</p>
      <p class="message">
        {{ cashback.message ? cashback.message.text : 'Sem mensagem configurada' }}
      </p>
          
      <p class="title">Lembrete de expiração</p>
      <div class="expiration" >
        <p>
          {{ cashback.expiration_message ? cashback.expiration_message.remind_days_before : '-' }} dia(s)
          antes da expiração
        </p>
        <p class="time">Hora do envio</p>
        <span>
          &nbsp;&nbsp;-&nbsp;&nbsp;
          {{ cashback.expiration_message ? cashback.expiration_message.remind_hour : '-' }}
        </span>
      </div>
      <p class="message">
        {{ cashback.expiration_message ? cashback.expiration_message.text : 'Sem mensagem configurada' }}
      </p>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    cashback: { type: Object, default: () => {} },
  },
  data() {
    return {};
  },
  methods: {
    formatPercent() {
      return `${+this.cashback.percentage}%`;
    },
    formatHour(time) {
      return dayjs(time).format('HH:mm');
    },
    formatCurrency(value) {
      const formated = parseFloat(value);

      return formated.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>
<style scoped>
.resume-cashback {
  background: var(--box);
  border-radius: 10px;
  width: 50%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.resume-cashback .cashback {
  background: rgba(46, 52, 56, 0.1);
  border: 1px dashed rgba(111, 118, 126, 0.15);
  border-radius: 10px;
  padding: 20px;
  margin: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-cashback .cashback p {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: rgba(170, 170, 171, 0.9);
}

.resume-cashback .cashback .value {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: var(--default);
}

.resume-cashback .details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 15px;
}

.resume-cashback .details .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume-cashback .details .item:not(:first-child) {
  border-left: 1px solid rgba(111, 118, 126, 0.2);
}

.resume-cashback .details .item p {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: var(--label-primary);
  margin-bottom: 15px;
}

.resume-cashback .details .item span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: rgba(190, 190, 191, 0.8);
}

.resume-cashback .messages {
  background: #292f33;
  border-radius: 0px 0px 10px 10px;
  padding: 20px;
  flex: 1;
}

.resume-cashback .messages .title {
  font-size: 14px;
  line-height: 17px;
  color: var(--label-primary);
  margin-bottom: 20px;
}

.resume-cashback .messages .title:not(:first-child) {
  border-top: 1px solid rgba(111, 118, 126, 0.15);
  padding-top: 20px;
  margin-top: 20px;
}

.resume-cashback .messages .message {
  background: #2e3438;
  border-radius: 10px;
  padding: 15px;
  font-size: 12px;
  line-height: 17px;
  color: rgba(204, 204, 204, 0.55);
  font-style: italic;
}

.resume-cashback .messages .expiration {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: rgba(190, 190, 191, 0.8);
  margin-bottom: 15px;
}

.resume-cashback .messages .expiration .time {
  font-weight: 400;
  color: #bebebf;
  border-left: 1px solid rgba(111, 118, 126, 0.15);
  padding-left: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 991px) {
  .resume-cashback {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .resume-cashback .details {
    grid-template-columns: 35% 30% 35%;
    padding: 0 10px;
  }
}
</style>
