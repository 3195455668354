<template>
  <div>
    <base-title
      :title="StringLanguage.myAccount"
      @addAction="openAddUser()"
      :showButtonAdd="selectedTab === 2"
    >
      <base-button @click="openAddUser()" v-if="selectedTab === 2 && !isMobile">
        Adicionar usuário
      </base-button>
    </base-title>

    <div class="tabs">
      <ul>
        <li>
          <a @click="selectedTab = 0" :class="{ active: selectedTab === 0 }">
            Meus dados
          </a>
        </li>
        <!--         <li>
          <a @click="selectedTab = 1" :class="{ active: selectedTab === 1 }">
            Dados da loja
          </a>
        </li> -->
        <li>
          <a @click="selectedTab = 2" :class="{ active: selectedTab === 2 }">
            Usuários
          </a>
        </li>
      </ul>
    </div>

    <div v-if="selectedTab === 0">
      <div class="account-container">
        <div class="user-profile">
          <div class="user-icon">
            <i class="far fa-user" />
          </div>
          <p class="username">{{ originalAccount.username }}</p>

          <div class="d-flex mt_20" :class="{ 'flex-column w-100': isMobile }">
            <base-button
              design="three"
              class="mini"
              :class="{ 'mb_10 w-100': isMobile }"
              @click="openDrawer('me')"
            >
              Editar informações
            </base-button>
            <base-button
              design="three"
              class="mini"
              :class="isMobile ? 'w-100' : 'ml_20'"
              @click="openDrawer('password')"
            >
              Alterar senha
            </base-button>
          </div>
        </div>

        <div class="form-user">
          <p class="title">Nome de usuário</p>
          <span>{{ originalAccount.name }}</span>
          <p class="title">E-mail</p>
          <span>{{ originalAccount.email }}</span>
          <p class="title">Celular</p>
          <span>{{ Utils.phoneMask(String(originalAccount.phone)) }}</span>
          <p class="title">Aniversário</p>
          <span>{{ originalAccount.birthdate }}</span>
        </div>
      </div>
    </div>

    <div v-if="selectedTab === 1">
      <div class="account-container">
        <div class="store-profile">
          <div class="store-icon">
            <div class="no-picture">
              <i class="far fa-image" />
            </div>
          </div>
          <p class="username">Churras Bar</p>
          <i
            class="far fa-pen edit"
            @click="drawer.store.open = !drawer.store.open"
          />
        </div>

        <div class="store-infos">
          <p class="title">Dias de abertura</p>
          <div class="days">
            <span>Segunda-feira</span>
            <span>Terça-feira</span>
            <span>Quarta-feira</span>
            <span>Quinta-feira</span>
          </div>

          <p class="title">Categorias do estabelecimento</p>
          <div class="categories">
            <span>Brasileira</span>
            <span>Nordestina</span>
          </div>

          <p class="title">Ambiente</p>
          <span>cafeteriadacidade</span>
        </div>
      </div>
    </div>

    <Users v-if="selectedTab === 2" ref="users" />

    <base-drawer
      title="Editar"
      :open="drawer.store.open"
      :close="drawer.store.close"
    >
      <base-tabs :tabs="modeTabs" :tabActived="tabActive" @change="changeTab" />

      <div v-if="tabActive === 0">
        <base-card>
          <div class="store-details">
            <label class="store-picture" for="store-picture">
              <div class="no-picture">
                <i class="far fa-image" />
              </div>
              <input type="file" id="store-picture" />
              <span class="add">
                <i class="fas fa-plus" />
              </span>
            </label>

            <base-input label="Nome do estabelecimento" design="three" />
          </div>
        </base-card>

        <base-card>
          <base-label>Dias da semana</base-label>
          <base-checkbox family="day" class="mb_10">
            Segunda-feira
          </base-checkbox>
          <base-checkbox family="day" class="mb_10">Terça-feira</base-checkbox>
          <base-checkbox family="day" class="mb_10">Quarta-feira</base-checkbox>
          <base-checkbox family="day" class="mb_10">Quinta-feira</base-checkbox>
          <base-checkbox family="day" class="mb_10">Sexta-feira</base-checkbox>
          <base-checkbox family="day" class="mb_10">Sábado</base-checkbox>
          <base-checkbox family="day" class="mb_10">Domingo</base-checkbox>
        </base-card>
      </div>

      <div v-if="tabActive === 1">
        <base-card>
          <base-label>Defina as categorias</base-label>

          <base-input
            placeholder="Buscar por..."
            search
            design="search-2"
            :records="15"
          />

          <div class="available-categories">
            <div class="category">
              <base-checkbox family="category">Pizzaria</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Italiana</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Brasileira</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Japonesa</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Hamburgueria</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Nordestina</base-checkbox>
            </div>
            <div class="category">
              <base-checkbox family="category">Vegana</base-checkbox>
            </div>
          </div>
        </base-card>
      </div>

      <div slot="footer">
        <base-button :text="StringLanguage.save" class="w-100" />
      </div>
    </base-drawer>

    <base-drawer title="Editar" :open="drawer.me.open" :close="drawer.me.close">
      <base-card>
        <custom-form ref="userInformationForm">
          <name-input v-model="oAccount.name" />
          <email-input v-model="oAccount.email" required />
          <cellphone-input v-model="oAccount.phone" required />
          <birthday-input v-model="oAccount.birthdate" required />
        </custom-form>
      </base-card>

      <div slot="footer">
        <base-button
          :text="StringLanguage.save"
          @click="validate('user-information')"
          :disabled="!hasChanges()"
          class="w-100"
        />
      </div>
    </base-drawer>

    <base-drawer
      title="Alterar senha"
      :open="drawer.password.open"
      :close="drawer.password.close"
    >
      <base-card>
        <custom-form ref="accessDataForm">
          <password-input
            :label="StringLanguage.currentPassword"
            v-model="currentPassword"
          />
          <double-pw-input
            :label="StringLanguage.newPassword"
            :labelConfirm="StringLanguage.repeatNewPassword"
            v-model="dbPassword"
            ref="dbPasswordInputs"
          />
        </custom-form>
      </base-card>

      <div slot="footer">
        <base-button
          :text="StringLanguage.save"
          @click="validate('password')"
          class="w-100"
        />
      </div>
    </base-drawer>

    <base-drawer
      ref="drawer"
      :open="drawer.createUser.open"
      :close="drawer.createUser.close"
      :title="
        selectedUser.id ? StringLanguage.editUser : StringLanguage.linkUser
      "
    >
      <users-form
        v-if="$refs.drawer && !!$refs.drawer.status"
        ref="user"
        @newUser="newUser"
        @editUser="editUser"
        @userDeleted="userDeleted"
        :selectedUser="selectedUser"
        :userError="userError"
        @setUserError="setUserError"
      />

      <template v-slot:footer>
        <div class="d-flex">
          <base-button
            v-if="selectedUser.id"
            class="mr_15"
            design="four"
            @click.prevent="deleteUser()"
            :title="StringLanguage.removeUser"
          >
            <i class="far fa-trash-alt" />
          </base-button>
          <base-button
            @click="saveUser()"
            :text="
              selectedUser.id ? StringLanguage.save : StringLanguage.create
            "
            class="w-100"
          />
        </div>
      </template>
    </base-drawer>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Vue from 'vue';
import { Role } from 'easyjs/lib/Role/Group';
import User from 'easyjs/lib/Schema/Account/User';
import { getXAPIEnv, getXUserKey } from '@/plugins/account';
import StoreAPI from '@/api/store';

import { UsersAPI } from '@/api/Users';

import Utils from '@/utils';

import BaseTitle from '@/components/Title';
import BaseLabel from '@/components/Label';
import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseTabs from '@/components/Tabs';
import BaseInput from '@/components/Input';
import BaseCheckbox from '@/components/Checkbox';
import Users from '@/pages/Account/Users';

import CustomForm from '@/containers/Form';
import NameInput from '@/containers/Inputs/NameInput';
import CellphoneInput from '@/containers/Inputs/CellphoneInput';
import EmailInput from '@/containers/Inputs/EmailInput';
import BirthdayInput from '@/containers/Inputs/BirthdayInput';
import PasswordInput from '@/containers/Inputs/PasswordInput';
import DoublePwInput from '@/containers/Inputs/DoublePwInput';

import UsersForm from '@/containers/Users/UsersForm';

export default {
  components: {
    BaseTitle,
    BaseLabel,
    BaseButton,
    BaseDrawer,
    BaseCard,
    BaseTabs,
    BaseInput,
    BaseCheckbox,
    CustomForm,
    NameInput,
    CellphoneInput,
    EmailInput,
    BirthdayInput,
    Users,
    UsersForm,
    PasswordInput,
    DoublePwInput,
  },
  data() {
    return {
      Utils,
      environmentAlias: getXAPIEnv(),
      selectedUser: {},
      selectedTab: 0,
      userError: null,
      tabActive: 0,
      modeTabs: [
        { name: 'Informações', value: 0 },
        { name: 'Categorias', value: 1 },
      ],
      drawer: {
        me: {
          open: false,
          close: false,
        },
        password: {
          open: false,
          close: false,
        },
        createUser: {
          open: false,
          close: false,
        },
        store: {
          open: false,
          close: false,
        },
      },
      originalAccount: new User({}),
      oAccount: new User({}),
      currentPassword: '',
      dbPassword: {
        password: '',
        confirmPassword: '',
      },
    };
  },
  created() {
    this.me();
  },
  methods: {
    changeTab(tab) {
      this.tabActive = tab;
    },
    newUser() {
      this.$refs.users.loadUsers();
      this.drawer.createUser.open = !this.drawer.createUser.open;
      this.drawer.createUser.close = !this.drawer.createUser.close;
      this.list();
    },
    editUser() {
      this.list();
      this.selectedUser = {};
    },
    openAddUser() {
      this.selectedUser = {};
      this.drawer.createUser.open = !this.drawer.createUser.open;
    },
    setUserError(prop) {
      this.userError = prop;
    },
    deleteUser() {
      this.$refs.user.del();
    },
    saveUser() {
      this.$refs.user.soe();
    },
    userDeleted() {
      this.list();
    },
    me() {
      UsersAPI.me().then((response) => {
        if (response._data && response._data.birthdate) {
          response._data.birthdate = Utils.iso2Br(response._data.birthdate);
        }
        this.set(response);
      });
    },
    set(response) {
      if (response.getErrors([]).length > 0) {
        alert(response.getErrors([])[0]);
        return false;
      }
      if (response.getData({}) || false) {
        const account = response.getData(new User({}));
        Vue.set(this, 'originalAccount', new User(account));
        Vue.set(this, 'oAccount', new User({ ...account }));
      } else {
        Vue.set(this, 'oAccount', new User({}));
      }
    },
    hasChanges() {
      return (
        JSON.stringify(this.originalAccount) != JSON.stringify(this.oAccount)
      );
    },
    validate(type) {
      if (type === 'password') {
        this.$refs.accessDataForm.validateInputs();

        const inputsPasswordValid = !this.$refs.accessDataForm.messages.length;

        if (!inputsPasswordValid) return;

        try {
          this.updatePassword(this.currentPassword, this.dbPassword);
        } catch (err) {
          return false;
        }
      }

      if (type === 'user-information') {
        this.$refs.userInformationForm.validateInputs();

        const userInformationValid =
          !this.$refs.userInformationForm.messages.length;

        if (!userInformationValid) return;

        try {
          this.save();
        } catch (err) {
          return false;
        }
      }
    },
    openDrawer(drawer) {
      this.drawer[drawer].open = !this.drawer[drawer].open;
    },
    closeDrawer(drawer) {
      this.drawer[drawer].close = !this.drawer[drawer].close;
      this.drawer[drawer].open = !this.drawer[drawer].open;

      if (drawer === 'password') this.resetInputsPassword();
    },
    resetInputsPassword() {
      this.currentPassword = '';

      this.dbPassword = {
        password: '',
        confirmPassword: '',
      };

      this.$refs.dbPasswordInputs.clearInputs();
    },
    save() {
      // const isMasterRole = this.isMaster();
      const birthdate = this.oAccount.birthdate
        ? Utils.br2Iso(this.oAccount.birthdate)
        : null;

      return UsersAPI.putMe({
        name: this.oAccount.name,
        username: this.oAccount.username,
        email: this.oAccount.email,
        phone: this.oAccount.phone,
        birthdate,
      }).then((response) => {
        if (response.getErrors([]).length > 0) {
          alert(response.getErrors(['Ocorreu um erro ao salvar os dados!'])[0]);
          return;
        }

        this.closeDrawer('me');

        alert('Dados salvos com sucesso!');
        this.me();
      });
    },
    list() {
      return UsersAPI.users().then((response) => {
        this.aUsers = response.getData([]);
      });
    },
    isMaster() {
      return this.originalAccount.group === Role.MASTER;
    },
    updatePassword(password, newPassword) {
      UsersAPI.ChangePassword({
        password,
        newPassword,
        confirmPassword: newPassword,
      }).then((response) => {
        if (response.getErrors([]).length > 0) {
          alert(response.getErrors(['Ocorreu um erro ao salvar os dados!'])[0]);
          return false;
        }

        this.closeDrawer('password');

        alert('Senha alterada com sucesso!');
      });
    },
  },
  computed: {
    ...mapState({
      storeConfiguration: (state) => state.store.configurations,
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.edit {
  font-size: 15px;
  color: var(--icon-inactive);
  position: absolute;
  right: 15px;
  padding: 15px;
  bottom: 0;
  top: 0;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.store-details {
  background: #2a3034;
  border-radius: 10px;
  padding: 20px 20px 5px;
}

.store-details .store-picture {
  background: rgba(26, 29, 31, 0.2);
  width: 112px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding: 5px;
}

.store-details .store-picture .add {
  background: #6161ff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
}

.no-picture {
  background: #32383d;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-picture i {
  color: var(--icon-inactive);
  font-size: 18px;
}

.store-details .store-picture input[type='file'] {
  display: none;
}

.available-categories {
  margin-top: 25px;
}

.available-categories .category:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}

.loading {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-container {
  background: var(--box);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 35px 35px 20px;
}

.account-container :is(.user-profile, .store-profile) {
  background: rgba(46, 52, 56, 0.1);
  border: 2px dashed rgba(111, 118, 126, 0.1);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.account-container :is(.form-user, .store-infos) {
  margin-top: 20px;
  padding-top: 10px;
  width: 100%;
}

.account-container :is(.form-user, .store-infos) p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #cccccc;
  margin-bottom: 10px;
}

.account-container :is(.form-user, .store-infos) p:not(:first-child) {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(111, 118, 126, 0.15);
}

.account-container :is(.form-user, .store-infos) .days {
  display: flex;
  flex-direction: column;
}

.account-container :is(.form-user, .store-infos) .days span {
  margin-bottom: 10px;
}

.account-container :is(.form-user, .store-infos) .categories {
  display: flex;
}

.account-container :is(.form-user, .store-infos) .categories span {
  padding: 15px 45px;
  background: #2e3438;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-top: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.65);
}

.account-container :is(.form-user, .store-infos) span {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.65);
}

.user-icon,
.store-icon {
  border: 3px solid rgba(26, 29, 31, 0.25);
  border-radius: 50%;
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon i {
  border-radius: 50%;
  font-size: 18px;
  color: var(--icon-inactive);
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-icon {
  background: rgba(26, 29, 31, 0.35);
}

.account-container p.username {
  margin-top: 15px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(204, 204, 204, 0.65);
}

.suffix {
  background: var(--box);
  font-size: 15px;
  color: var(--icon-inactive);
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  border: 1px solid var(--input-border);
  margin: auto;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.suffix:hover {
  background: var(--box-up);
}

.tabs {
  margin-bottom: 50px;
}
.tabs ul:not(.options-list) {
  display: flex;
  border-bottom: 2px solid rgba(111, 118, 126, 0.15);
}
.tabs a {
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
  font-size: 12px;
  color: #999999ad;
  padding-bottom: 15px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
}
.tabs a.active {
  color: var(--default);
  border-color: var(--default);
}

@media screen and (max-width: 991px) {
  .tabs {
    margin-bottom: 35px;
  }
}
</style>
