<template>
  <div class="grid-indicators">
    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ formatCurrency(reports.distributed || 0) }}</p>
        <i class="far fa-hand-holding-dollar" />
      </div>
      <span class="description">Total de cashback concebido</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ reports.sales_count || 0 }}</p>
      </div>
      <span class="description">Total de vendas com cashback</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ formatCurrency(reports.sales_total || 0) }}</p>
      </div>
      <span class="description">Total em vendas com cashback</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ formatCurrency(reports.expired_cashback || 0) }}</p>
      </div>
      <span class="description">Total de cashback expirado</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">
          {{ formatCurrency(getRawEarn()) }}
        </p>
      </div>
      <span class="description">Margem bruta</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ reports.new_customers || 0 }}</p>
      </div>
      <span class="description">Novos cadastros gerados</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ reports.distribution_sent }}</p>
      </div>
      <span class="description">Disparos de cashback</span>
      <span class="line"></span>
    </div>

    <div class="card">
      <div class="d-flex align-center">
        <p class="value">{{ reports.expiration_sent }}</p>
      </div>
      <span class="description">Disparos de expiração</span>
      <span class="line"></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    reports: {},
  },
  methods: {
    getRawEarn() {
      const reports = this.reports;
      return ((reports.sales_total || 0) - (reports.distributed || 0));
    },
    formatCurrency(value) {
      const formated = parseFloat(value);

      return formated.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
};
</script>
<style scoped>
.grid-indicators {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 50%;
}

.grid-indicators .card {
  background: var(--box);
  padding: 20px 25px;
  border-radius: 10px;
}

.grid-indicators .card .value {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: var(--label-secondary);
  margin-bottom: 5px;
}

.grid-indicators .card .value + i {
  color: #6161ff;
  margin-left: 10px;
  font-size: 14px;
  margin-bottom: 5px;
}

.grid-indicators .card .description {
  font-size: 12px;
  line-height: 14px;
  color: rgba(204, 204, 204, 0.4);
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--box-up);
}

@media screen and (max-width: 991px) {
  .grid-indicators {
    width: 100%;
    margin-bottom: 20px;
    gap: 10px;
  }

  .grid-indicators .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px;
  }
}
</style>
