<template>
  <base-drawer
    title="Ver passado"
    :open="open"
    :close="close"
    @onClose="closeDrawer"
  >
    <base-card>
      <div class="events">
        <div class="event" v-for="(item, itemIndex) in historyItems" :key="itemIndex">
          <div class="d-flex justify-between mb_30">
            <p class="change">Alteração do programa</p>
            <p class="date">{{getDate(item.deleted_at)}}</p>
          </div>

          <p class="period">Período considerado</p>

          <div class="d-flex align-center justify-between">
            <p class="badge-days">{{getDateDiffDays(item.deleted_at || new Date(), item.created_at)}} dias</p>
            <p class="period-between">{{getDate(item.created_at)}} - {{item.deleted_at ? getDate(item.deleted_at) : 'o momento'}}</p>
          </div>

          <div class="line"></div>

          <p class="changes" v-if="item.changed_fields.length > 0">Alterações</p>

          <div class="tags" :style="{ flexWrap: 'wrap' }">
            <div class="tag" v-for="(field, fieldIndex) in getGroupedChangedFields(item.changed_fields)" :key="fieldIndex">
              •&nbsp;&nbsp;{{ field }}
            </div>
          </div>
          <base-button design="five" @click="seeResults(item)">
            Ver resultados
          </base-button>
        </div>
        <infinite-loading
          spinner="wavedots"
          @infinite="loadHistoryItems"
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </base-card>
  </base-drawer>
</template>
<script>

import dayjs from 'dayjs';
import { mapState } from 'vuex';

import BaseButton from '@/components/Button';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import InfiniteLoading from "vue-infinite-loading";


import { CashbackProgramAPI } from '@/api/CashbackProgramAPI'


const components = {
  BaseButton,
  BaseDrawer,
  BaseCard,
  InfiniteLoading
};

export default {
  components,
  props: {
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
  },
  data() {
    return {
      page: { page: 1, limit: 10 },
      historyItems: []
    }
  },
  methods: {
    async loadHistoryItems($state) {
      const response = await CashbackProgramAPI.getProgramHistory(this.page.page, this.page.limit);
      const data = response.getData({ next: { page: -1, limit: 10 }, programs: [] });
      this.historyItems = [...this.historyItems, ...data.programs];
      this.page = data.next;

      if($state) {
        if (this.page.page == -1) {
          $state.complete();
        } else {
          $state.loaded();
        }
      }
    },
    getFieldName(field) {
      const fields = {
        'percentage': 'Cashback',
        'min_order': 'Cashback',
        'max_gain': 'Cashback',
        "expiration_message": 'Lembrete',
        "expiration_message.type": 'Lembrete',
        "expiration_message.link": 'Lembrete',
        "expiration_message.text": 'Lembrete',
        "expiration_message.remind_hour": 'Lembrete',
        "expiration_message.remind_days_before": 'Lembrete',
        "message": 'Comunicação',
        "message.type": 'Comunicação',
        "message.link": 'Comunicação',
        "message.text": 'Comunicação',
        'expiration_days': 'Cashback',
      }

      return fields[field] || 'Alteração desconhecida'
    },
    getGroupedChangedFields(changed_fields = []) {
      const groupedFields = [];

      for(const field of changed_fields) {
        groupedFields.push(this.getFieldName(field));
      }
      return new Set(groupedFields);
    },
    getDate(dateString) {
      return dayjs(dateString).format('DD/MM/YYYY')
    },
    getDateDiffDays(startDateString, endDateString) {
      const date1 = dayjs(startDateString)
      return date1.diff(endDateString, 'day') 
    },
    closeDrawer() {
      this.$emit('onClose');
    },
    seeResults(i) {
      this.closeDrawer();
      this.$emit('seeResults', i);
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>
<style scoped>
.events {
  position: relative;
  padding-left: 20px;
}

.events:before {
  content: '';
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border-left: 2px dashed #ffffff17;
  position: absolute;
}

.events .event {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
}

.events .event:not(:last-child) {
  margin-bottom: 20px;
}

.events .event:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  position: absolute;
  left: -23px;
  margin: auto;
  background: var(--default);
}

.events .event p:is(.change, .date) {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: var(--label-primary);
}

.events .event p:is(.period, .changes) {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(170, 170, 171, 0.85);
  margin-bottom: 15px;
}

.events .event p.changes {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 15px;
  padding-top: 15px;
}

.events .event p:is(.badge-days, .period-between) {
  color: var(--label-primary);
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.events .event p.badge-days {
  border: 1px solid rgba(111, 118, 126, 0.2);
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
}

.events .event .tags {
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
}

.events .event .tags .tag {
  background: #292e32;
  border-radius: 10px;
  padding: 5px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  color: var(--label-primary);
}

.events .infinite-loading-container {
  height: auto;
}

</style>
