<template>
  <div>
    <base-title title="Base de dados" showSearch @search="search = $event">
      <base-input
        search
        v-model="search"
        :records="occurrences"
        placeholder="Buscar por..."
        class="mb_0 pt_0"
        design="search"
        v-if="!isMobile"
      />

      <base-button
        design="two"
        :class="isMobile ? 'reverse w-100' : 'ml_15'"
        @click="openDrawer()"
      >
        <i class="far fa-sliders-h" v-if="!isMobile" />
        <p class="filter" v-else>Filtragem</p>
      </base-button>
    </base-title>

    <Customers :customers="customers" />
    <infinite-loading @infinite="infiniteHandler">
      <span slot="no-results">
        <base-empty
          title="Você ainda não tem conversões realizadas."
          message="Todas as conversões irão aparecer aqui."
        >
          <template slot="image">
            <i class="far fa-database" />
          </template>
        </base-empty>
      </span>
      <span slot="no-more"></span>
      <span slot="spinner">
        <base-loading />
      </span>
    </infinite-loading>

    <base-drawer :open="drawer.open" :close="drawer.close" title="Filtragem">
      <base-card>
        <base-label text="Dados básicos" class="mb_20" />
        <base-checkbox @click="mountSimpleSearch({ phone: true })" class="mb_15"
          >Telefone</base-checkbox
        >
        <base-checkbox class="mb_15" @click="mountSimpleSearch({ name: true })"
          >Nome</base-checkbox
        >
        <base-checkbox class="mb_15" @click="mountSimpleSearch({ email: true })"
          >Email</base-checkbox
        >
        <base-checkbox class="mb_15" v-model="lgpdFilter">LGPD</base-checkbox>
        <base-checkbox
          class="mb_15"
          @click="mountSimpleSearch({ birthdate: true })"
          >Aniversário</base-checkbox
        >
        <base-checkbox @click="mountSimpleSearch({ address: true })">
          Endereço</base-checkbox
        >
        <!-- <base-checkbox v-model="lgpdFilter" class="mb_15">LGPD</base-checkbox> -->
      </base-card>

      <base-card>
        <base-label text="Tempo de cadastro" class="mb_20" />
        <div class="inputs-range">
          <div class="from-to">
            <span>De</span>
            <base-input
              class="mb_0"
              :valid="!validator.createdAt"
              :key="random"
              :mask="StringLanguage.dateMask"
              v-model="range.createdAt.min"
            />
          </div>
          <div class="from-to">
            <span>Até</span>
            <base-input
              class="mb_0"
              v-model="range.createdAt.max"
              :valid="!validator.createdAt"
              :key="random"
              :mask="StringLanguage.dateMask"
            />
          </div>
        </div>
      </base-card>

      <base-card>
        <base-label text="Conversões" class="mb_20" />
        <div class="inputs-range">
          <div class="from-to">
            <span>De</span>
            <base-input
              v-model="range.countOrders.min"
              :valid="!validator.countOrders"
              :key="random"
              class="mb_0"
            />
          </div>
          <div class="from-to">
            <span>Até</span>
            <base-input
              v-model="range.countOrders.max"
              :valid="!validator.countOrders"
              :key="random"
              class="mb_0"
            />
          </div>
        </div>
      </base-card>

      <base-card>
        <base-label text="Total gasto" class="mb_20" />
        <div class="inputs-range">
          <div class="from-to">
            <span>De</span>
            <base-input
              v-model="range.totalTicket.min"
              :valid="!validator.totalTicket"
              :key="random"
              class="mb_0"
            />
          </div>
          <div class="from-to">
            <span>Até</span>
            <base-input
              v-model="range.totalTicket.max"
              :valid="!validator.totalTicket"
              :key="random"
              class="mb_0"
            />
          </div>
        </div>
      </base-card>

      <base-card>
        <base-label text="Ticket médio" class="mb_20" />
        <div class="inputs-range">
          <div class="from-to">
            <span>De</span>
            <base-input
              class="mb_0"
              v-model="range.averageTicket.min"
              :valid="!validator.averageTicket"
              :key="random"
            />
          </div>
          <div class="from-to">
            <span>Até</span>
            <base-input
              v-model="range.averageTicket.max"
              class="mb_0"
              :valid="!validator.averageTicket"
              :key="random"
            />
          </div>
        </div>
      </base-card>

      <base-card>
        <base-label text="Idade" />
        <div class="inputs-range">
          <div class="from-to">
            <span>De</span>
            <base-input
              v-model="range.age.min"
              class="mb_0"
              :valid="!validator.age"
              :key="random"
            />
          </div>
          <div class="from-to">
            <span>Até</span>
            <base-input
              class="mb_0"
              :key="random"
              v-model="range.age.max"
              :valid="!validator.age"
              @change="mountSearch({ age: range.age })"
            />
          </div>
        </div>
      </base-card>

      <template slot="footer">
        <base-button class="w-100 big" @click.native="searchDrawer">
          Salvar
        </base-button>
      </template>
    </base-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { mapState } from 'vuex';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import InfiniteLoading from 'vue-infinite-loading';
import BaseTitle from '@/components/Title';
import BaseDrawer from '@/components/Drawer';
import BaseCard from '@/components/Card';
import BaseButton from '@/components/Button';
import BaseInput from '@/components/Input';
import BaseLabel from '@/components/Label';
import BaseCheckbox from '@/components/Checkbox';
import BaseEmpty from '@/components/Empty';
import BaseLoading from '@/components/Loading';
import Customers from '@/containers/Database/Customers';
import { EnvironmentAPI } from '@/api/Environment';

dayjs.extend(customParseFormat);

export default {
  components: {
    BaseTitle,
    BaseDrawer,
    BaseCard,
    BaseButton,
    BaseInput,
    BaseLabel,
    BaseCheckbox,
    Customers,
    InfiniteLoading,
    BaseEmpty,
    BaseLoading,
    VueSlider,
  },
  data() {
    return {
      searchTimout: null,
      random: Math.random(),
      validator: {},
      lgpdFilter: false,
      filterLgpd: {},
      rangeSearch: {},
      simpleSearch: [],
      query: [{ has_props: [] }],
      search: '',
      infiniteHandlerLimit: false,
      validate: {},
      occurrences: 0,
      page: 0,
      range: {
        createdAt: { min: '', max: '' },
        countOrders: { min: '', max: '' },
        totalTicket: { min: '', max: '' },
        averageTicket: { min: '', max: '' },
        age: { min: '', max: '' },
      },
      customers: [],
      drawer: {
        open: false,
        close: false,
      },
    };
  },
  watch: {
    lgpdFilter(newVal) {
      this.filterLgpd = {
        consent: newVal,
      };
    },
    search(newVal) {
      if (this.searchTimout) {
        clearTimeout(this.searchTimout);
      }
      this.searchTimout = setTimeout(() => {
        // this.simpleSearch = newVal;
        // this.mountSearch({ simpleSearch: newVal });
        this.page = 0;
        this.customers = [];
        this.searchAll(newVal);
      }, 1000);
    },
  },
  methods: {
    async infiniteHandler($state) {
      const { page } = this;
      const response = await EnvironmentAPI.requestCustomers(page, {
        hasProp: this.simpleSearch,
        ...this.rangeSearch,
        ...this.filterLgpd,
      });
      this.occurrences = response.getData().founded;
      if (response.getData([]).customers.length > 0) {
        this.customers.push(...response.getData([]).customers);
        this.page += 1;
        $state && $state.loaded();
      } else {
        // this.customers = response.getData([]).customers;
        $state && $state.complete();
      }
    },
    async getCustomers() {
      const response = await EnvironmentAPI.requestCustomers();
      this.occurrences = response.getData().founded;

      return response;
    },
    searchAll(search) {
      if (search === '') {
        this.page = 0;
        this.hasProp = {};
        this.rangeSearch = [];
        this.infiniteHandler();
      } else {
        search = search.replaceAll('+', '%2b');
        this.rangeSearch = { name: search };
        this.infiniteHandler();
      }
    },
    searchDrawer() {
      this.customers = [];
      this.page = 0;
      const processedRange = {};
      const entries = Object.entries(this.range);
      const rangeHandlers = {
        numeric: (range) => {
          if (parseInt(range.min) < parseInt(range.max)) {
            return range;
          }

          return null;
        },
        date: (range) => {
          const min = dayjs(range.min, 'DDMMYYYY');
          const max = dayjs(range.max, 'DDMMYYYY');

          if (!min.isValid() || !max.isValid()) {
            return null;
          }

          return {
            min: min.format('YYYY-MM-DD'),
            max: max.format('YYYY-MM-DD'),
          };
        },
      };
      entries.forEach((item) => {
        const field = item[0];
        const value = item[1];
        let newValue = null;
        if (field === 'createdAt') {
          newValue = rangeHandlers.date(value);
        } else {
          newValue = rangeHandlers.numeric(value);
        }

        if (newValue) {
          processedRange[field] = newValue;
        }

        this.rangeSearch = processedRange;
      });
      this.infiniteHandler();
      this.drawer.open = !this.drawer.open;
      this.drawer.close = true;
      this.random = Math.random();
      this.validator = {};
    },
    mountSimpleSearch(params) {
      // this.lgpdFilter = params;

      if (!this.simpleSearch.includes(Object.keys(params)[0])) {
        this.simpleSearch.push(Object.keys(params)[0]);
      } else {
        this.simpleSearch.splice(
          this.simpleSearch.indexOf(Object.keys(params)[0]),
          1
        );
      }
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = false;
    },
  },

  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped>
.inputs-range {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.inputs-range .from-to span:first-child {
  margin-bottom: 5px;
  display: block;
  color: #aaaaab;
  opacity: 0.7;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}
</style>
