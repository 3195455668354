<template>
  <div>
    <base-title
      title="Detalhes de benefício"
      link="beneficios"
      subtitle="Cupons"
    >
      <base-button
        design="two"
        :class="isMobile ? 'reverse w-100' : ''"
        :disabled="benefit.status == 'ARCHIVED'"
        @click="archiveBenefit()"
      >
        Arquivar cupom
      </base-button>
    </base-title>

    <div class="wrapper" v-if="benefit.id">
      <standalone-coupon-infos :benefit="benefit" />
      <standalone-coupon-metrics :benefit="benefit" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Utils from '@/utils';

import BaseTitle from '@/components/Title';
import BaseButton from '@/components/Button';

import { StandaloneCouponsAPI } from '@/api/StandaloneCouponsAPI';

import StandaloneCouponInfos from '@/containers/StandaloneCoupon/StandaloneCouponInfos';
import StandaloneCouponMetrics from '@/containers/StandaloneCoupon/StandaloneCouponMetrics';

export default {
  components: {
    BaseTitle,
    BaseButton,
    StandaloneCouponInfos,
    StandaloneCouponMetrics,
  },
  props: {},
  data() {
    return {
      Utils,
      benefit: { reports: [] },
    };
  },
  async mounted() {
    await this.loadBenefit();
  },
  methods: {
    async loadBenefit() {
      const { id } = this.$route.params;
      const loadedCoupon = await StandaloneCouponsAPI.getStandaloneCouponById(
        id
      );

      this.benefit = loadedCoupon.getData({});
    },
    async archiveBenefit() {
      const confirmText = 'Você tem certeza que deseja arquivar este cupom? Esta ação não poderá ser desfeita.';
      const confirmed = await (new Promise((resolve) => confirm(confirmText, resolve, 'Arquivar cupom', 'Cancelar')));
      if(confirmed) {
        const response = await StandaloneCouponsAPI.putStandaloneCouponStatus(this.benefit.id, 'ARCHIVED');
        const errors = response.getErrors([]);
        if(errors.length > 0) {
          alert(errors[0]);
        } else {
          this.benefit.status = "ARCHIVED";
          alert('Benefício arquivado com sucesso');
        }
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    })
  },
};
</script>

<style scoped>
.details-benefit {
  border-radius: 10px;
  background: var(--box);
  padding: 20px;
  margin-bottom: 30px;
}

.details-benefit .benefit-code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px dashed #363e42;
  background: #272c30;
  border-radius: 10px;
  padding: 15px 40px;
  text-align: center;
}

.details-benefit .benefit-code p {
  color: rgba(170, 170, 171, 0.85);
  font-size: 14px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}

.details-benefit .benefit-code span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: rgba(190, 190, 191, 0.8);
}

.details-benefit .step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.details-benefit .step:not(:last-child) {
  border-bottom: 1px solid rgba(111, 118, 126, 0.15);
}

.details-benefit .step:last-child {
  padding-bottom: 10px;
}

.details-benefit .step p:nth-child(1) {
  font-size: 14px;
  line-height: 17px;
  color: #aaaaab;
  margin-bottom: 15px;
}

.dashboard-benefit {
  background: var(--box);
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
}

.details-benefit .step p:nth-child(2) {
  color: rgba(190, 190, 191, 0.8);
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
}

.totalizer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.totalizer .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: var(--box);
  border-radius: 10px;
  padding: 15px 25px;
}

.totalizer .card i {
  color: var(--default);
  font-size: 14px;
  margin-left: 5px;
}

.totalizer .card .value {
  color: #cccccc;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}

.totalizer .card p:nth-child(2) {
  color: rgba(204, 204, 204, 0.4);
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--box-up);
}

@media screen and (min-width: 992px) {
  .wrapper {
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 700;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 700;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>
