<template>
  <div>
    <base-label text="Defina o método de envio" />
    <div class="grid-options">
      <div
        class="option"
        :class="{
          active: selected === shippingMethods.SMS,
        }"
        @click="selected = shippingMethods.SMS"
      >
        <i v-if="selected !== shippingMethods.SMS" class="far fa-comment" />
        <i v-if="selected === shippingMethods.SMS" class="far fa-check" />
        <p>SMS</p>
      </div>

      <div
        class="option disabled"
        :class="{
          active: selected === shippingMethods.WHATSAPP,
        }"
        @click="selected = shippingMethods.WHATSAPP"
      >
        <i
          v-if="selected !== shippingMethods.WHATSAPP"
          class="fa-brands fa-whatsapp"
        />
        <i v-if="selected === shippingMethods.WHATSAPP" class="far fa-check" />
        <div class="d-flex align-center">
          <p>WhatsApp</p>
        </div>
        <base-badge type="exclamation" name="Em breve" class="badge" />
      </div>

      <div
        class="option disabled"
        :class="{
          active: selected === shippingMethods.EMAIL,
        }"
        @click="selected = shippingMethods.EMAIL"
      >
        <i
          v-if="selected !== shippingMethods.EMAIL"
          class="far fa-envelope-open"
        />
        <i v-if="selected === shippingMethods.EMAIL" class="far fa-check" />
        <div class="d-flex align-center">
          <p>Email</p>
        </div>
        <base-badge type="exclamation" name="Em breve" class="badge" />
      </div>

      <div
        class="option disabled"
        :class="{
          active: selected === shippingMethods.FALLBACK,
        }"
        @click="selected = shippingMethods.FALLBACK"
      >
        <i
          v-if="selected !== shippingMethods.FALLBACK"
          class="far fa-arrow-down-big-small"
        />
        <i v-if="selected === shippingMethods.FALLBACK" class="far fa-check" />
        <div class="d-flex align-center">
          <p>Fallback</p>
        </div>
        <base-badge type="exclamation" name="Em breve" class="badge" />
      </div>
    </div>

    <base-checkbox
      :checked="machineLearning"
      v-model="machineLearning"
      class="mt_20"
    >
      <p class="text-checkbox">Aprendizagem automática</p>
    </base-checkbox>
  </div>
</template>

<script>
import BaseLabel from '@/components/Label';
import BaseBadge from '@/components/Badge';
import BaseCheckbox from '@/components/Checkbox';

const shippingMethods = {
  SMS: 'SMS',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  FALLBACK: 'fallback',
};

export default {
  components: {
    BaseLabel,
    BaseBadge,
    BaseCheckbox,
  },
  props: {
    shippingMethod: { type: String, default: '' },
  },
  data() {
    return {
      selected: this.shippingMethod,
      machineLearning: false,
      shippingMethods,
    };
  },
  watch: {
    selected(newValue) {
      this.$emit('selectShippingMethod', newValue);
    },
  },
};
</script>

<style scoped>
.grid-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.grid-options .option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--box-up);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  position: relative;
}

.grid-options .option.disabled {
  background: #2e343873;
  cursor: default;
  pointer-events: none;
}

.grid-options .option .badge {
  right: 10px;
  top: 10px;
}

.grid-options .option i {
  color: #6f767e;
  font-size: 25px;
  margin-bottom: 15px;
}

.grid-options .option.disabled i {
  opacity: 0.45;
}

.grid-options .option :is(p, span) {
  color: #aaaaab;
  font-size: 12px;
  line-height: 15px;
}

.grid-options .option.disabled p {
  opacity: 0.45;
}

.grid-options .option.active :is(p, i) {
  color: var(--default);
}
</style>
