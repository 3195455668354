<template>
  <div>
    <base-drawer
      :open="open"
      :close="close"
      @onClose="closeDrawer"
      title="QR Code"
    >
      <base-card>
        <div class="qrcode-container">
          <div class="qrcode" v-if="!loading && qrcode">
            <img
              :src="qrcode"
              class="img-fluid mobile"
              alt="Integrações"
              id="qrcode-image"
            />
            <base-button design="five" @click="downloadQrCode()"
              >Salvar QR Code</base-button
            >
            <base-button design="five" class="mt_10" @click="print()">
              Imprimir QR Code
            </base-button>
          </div>
        </div>
      </base-card>
    </base-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import BaseDrawer from '@/components/Drawer';
import BaseButton from '@/components/Button';
import BaseCard from '@/components/Card';
import { QRCode } from '@/plugins/qrcode';
import { EnvironmentsAPI } from '@/api/Environments';

export default {
  components: {
    BaseDrawer,
    BaseButton,
    BaseCard,
  },
  props: {
    benefit: { type: Object, default: null },
    open: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
  },
  data() {
    return {
      showQrCode: false,
      qrcode: null,
      loading: false,
    };
  },
  mounted() {
    const benefit = this.benefit;
    if (benefit && benefit.code && benefit.pin) {
      this.generateQrCode(benefit.code, benefit.pin);
    }
  },
  methods: {
    print() {
      const iframe = document.createElement('iframe');
      // Make it hidden
      iframe.style.height = 0;
      iframe.style.visibility = 'hidden';
      iframe.style.width = 0;

      // Set the iframe's source
      iframe.setAttribute('srcdoc', '<html><body></body></html>');

      document.body.appendChild(iframe);

      iframe.addEventListener('load', function () {
        // Clone the image
        const image = document.getElementById('qrcode-image').cloneNode();
        image.style.maxWidth = '100%';

        // Append the image to the iframe's body
        const body = iframe.contentDocument.body;
        body.style.textAlign = 'center';
        body.appendChild(image);

         image.addEventListener('load', function() {
            // Invoke the print when the image is ready
            iframe.contentWindow.print();
        });    
      });
    },
    async generateQrCode(code, pin) {
      this.loading = true;
      const { _data } = await EnvironmentsAPI.getConfigurations(false);
      this.urlStore = `${_data['short-urls'].rawShortWallet}?code=${code}&pin=${pin}`;
      this.qrcode = await QRCode.getDataUrl(this.urlStore);
      this.loading = false;
    },
    async downloadQrCode() {
      QRCode.downloadQRCodeImage(
        `qrcode-${this.benefit.code}-${this.benefit.pin}.png`,
        this.urlStore
      );
    },
    closeDrawer() {
      this.$emit('closeDrawer');
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
  watch: {
    open: {
      handler() {
        const benefit = this.benefit;
        if (benefit && benefit.code && benefit.pin) {
          this.generateQrCode(benefit.code, benefit.pin);
        }
      },
    },
  },
};
</script>

<style scoped>
.qrcode-container {
  padding: 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 700;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 700;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>
