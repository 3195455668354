<template>
  <div>
    <base-title
      title="Benefícios arquivados"
      link="beneficios"
      subtitle="Cupons"
    />

    <div class="benefits-list">
      <div
        v-for="(benefit, benefitIndex) in benefits"
        :key="benefitIndex"
        class="benefit-card"
      >
        <div class="header">
          <div class="d-flex align-center">
            <div class="icon-gift">
              <i class="fal fa-gift" />
            </div>
            <span class="code">{{ benefit.code }}&nbsp;&nbsp;-</span>
            <span class="pin">{{ benefit.pin.toUpperCase() }}</span>
          </div>

          <div class="d-flex align-center">
            <base-button design="five mini icon" @click="openDrawer()">
              <i class="far fa-qrcode" />
            </base-button>
            <div class="separator"></div>
            <base-button
              design="five mini icon"
              @click="changeRoute('detalhes-beneficio', { id: benefit.id })"
            >
              <i class="far fa-chart-line" />
            </base-button>
          </div>
        </div>

        <div class="content">
          <div class="infos">
            <div class="info">
              <p>Data arquivamento</p>
              <span>{{ dayjs(benefit.updated_at).format('DD/MM/YYYY') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <span slot="no-results">
        <base-empty title="Não há benefícios arquivados.">
          <template slot="image">
            <i class="fal fa-ticket-simple" style="transform: rotate(345deg)" />
          </template>
        </base-empty>
      </span>
    </infinite-loading>

    <qrcode
      :open="drawer.open"
      :close="drawer.close"
      @closeDrawer="closeDrawer"
    />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import BaseButton from '@/components/Button';
import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import Qrcode from '@/pages/Benefits/Qrcode';
import { StandaloneCouponsAPI } from '@/api/StandaloneCouponsAPI';

dayjs.extend(customParseFormat);

export default {
  components: {
    BaseButton,
    BaseTitle,
    Qrcode,
    BaseEmpty,
    InfiniteLoading,
  },
  data() {
    return {
      dayjs,
      drawer: {
        open: false,
        close: false,
      },
      benefits: [],
      pagination: {
        page: 0,
        limit: 12,
      },
    };
  },
  methods: {
    async infiniteHandler($state) {
      const { pagination } = this;

      if (pagination.page === -1) {
        $state && $state.complete();
        return;
      }

      const response = await StandaloneCouponsAPI.getStandaloneCoupons(
        'ARCHIVED',
        pagination.page,
        pagination.limit
      );

      const { items, next } = response.getData({
        items: [],
        next: { page: -1, limit: 18 },
      });

      this.pagination = next;

      if (items.length > 0) {
        this.benefits.push(...items);
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;
    },
    closeDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = !this.drawer.close;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
  },
};
</script>

<style scoped>
.benefits-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .benefits-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.benefit-card {
  background: #252a2e;
  border-radius: 10px;
  padding: 10px;
}

.benefit-card .header {
  background: rgba(30, 33, 36, 0.37);
  border-radius: 10px;
  padding: 5px 15px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.benefit-card .header .icon-gift {
  background: rgba(30, 33, 36, 0.45);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 42px;
  height: 42px;
}

.benefit-card .header .icon-gift i {
  color: #6f767e;
  font-size: 12px;
}

.benefit-card .header .code {
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #cccccc;
}

.benefit-card .header .pin {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #6161ff;
  margin-left: 5px;
}

.benefit-card .header .separator {
  height: 22px;
  width: 1px;
  background: rgba(111, 118, 126, 0.2);
  margin: 0 10px;
}

.benefit-card .content .infos {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.benefit-card .content .infos:first-child {
  padding-top: 25px;
  margin-top: 0;
}

.benefit-card .content .infos .info {
  display: flex;
  flex-direction: column;
}

.benefit-card .content .infos .info:last-child {
  align-items: flex-start;
}

.benefit-card .content .infos .info p {
  font-weight: 500;
  color: #cccccc;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
}

.benefit-card .content .infos .info span {
  color: rgba(204, 204, 204, 0.65);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}
</style>
