var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-label',{attrs:{"text":"Defina o método de envio"}}),_c('div',{staticClass:"grid-options"},[_c('div',{staticClass:"option",class:{
        active: _vm.selected === _vm.shippingMethods.SMS,
      },on:{"click":function($event){_vm.selected = _vm.shippingMethods.SMS}}},[(_vm.selected !== _vm.shippingMethods.SMS)?_c('i',{staticClass:"far fa-comment"}):_vm._e(),(_vm.selected === _vm.shippingMethods.SMS)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_c('p',[_vm._v("SMS")])]),_c('div',{staticClass:"option disabled",class:{
        active: _vm.selected === _vm.shippingMethods.WHATSAPP,
      },on:{"click":function($event){_vm.selected = _vm.shippingMethods.WHATSAPP}}},[(_vm.selected !== _vm.shippingMethods.WHATSAPP)?_c('i',{staticClass:"fa-brands fa-whatsapp"}):_vm._e(),(_vm.selected === _vm.shippingMethods.WHATSAPP)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_vm._m(0),_c('base-badge',{staticClass:"badge",attrs:{"type":"exclamation","name":"Em breve"}})],1),_c('div',{staticClass:"option disabled",class:{
        active: _vm.selected === _vm.shippingMethods.EMAIL,
      },on:{"click":function($event){_vm.selected = _vm.shippingMethods.EMAIL}}},[(_vm.selected !== _vm.shippingMethods.EMAIL)?_c('i',{staticClass:"far fa-envelope-open"}):_vm._e(),(_vm.selected === _vm.shippingMethods.EMAIL)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_vm._m(1),_c('base-badge',{staticClass:"badge",attrs:{"type":"exclamation","name":"Em breve"}})],1),_c('div',{staticClass:"option disabled",class:{
        active: _vm.selected === _vm.shippingMethods.FALLBACK,
      },on:{"click":function($event){_vm.selected = _vm.shippingMethods.FALLBACK}}},[(_vm.selected !== _vm.shippingMethods.FALLBACK)?_c('i',{staticClass:"far fa-arrow-down-big-small"}):_vm._e(),(_vm.selected === _vm.shippingMethods.FALLBACK)?_c('i',{staticClass:"far fa-check"}):_vm._e(),_vm._m(2),_c('base-badge',{staticClass:"badge",attrs:{"type":"exclamation","name":"Em breve"}})],1)]),_c('base-checkbox',{staticClass:"mt_20",attrs:{"checked":_vm.machineLearning},model:{value:(_vm.machineLearning),callback:function ($$v) {_vm.machineLearning=$$v},expression:"machineLearning"}},[_c('p',{staticClass:"text-checkbox"},[_vm._v("Aprendizagem automática")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[_c('p',[_vm._v("WhatsApp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[_c('p',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[_c('p',[_vm._v("Fallback")])])
}]

export { render, staticRenderFns }