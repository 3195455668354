<template>
  <div>
    <base-title title="Benefícios" v-if="benefits.length > 0">
      <base-button
        design="two"
        :class="isMobile ? 'reverse w-100 mb_15' : ''"
        @click="changeRoute('beneficios-arquivados')"
      >
        Ver arquivados
      </base-button>

      <base-button
        :class="isMobile ? 'reverse w-100 mt_15' : 'ml_15'"
        @click="openDrawer()"
      >
        Criar benefício
      </base-button>
    </base-title>

    <benefits-list :benefits="benefits" />
    <base-empty
      v-if="!benefits.length"
      title="Não há cupons avulsos cadastrados."
      message="Por meio desta ferramenta, será possível cadastrar cupons não vinculados a campanhas."
      buttonText="Criar benefício"
      @onClick="openDrawer()"
    >
      <template slot="image">
        <i class="fal fa-ticket-simple" style="transform: rotate(345deg)" />
      </template>
    </base-empty>
    <infinite-loading v-if="benefits.length" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <span slot="no-results">
      </span>
    </infinite-loading>

    <benefit-form
      :open="drawer.open"
      :close="drawer.close"
      :benefits="benefits"
      @onClose="closeDrawer()"
      @saved="save"
      :pin="pin"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import InfiniteLoading from 'vue-infinite-loading';
import BaseTitle from '@/components/Title';
import BaseEmpty from '@/components/Empty';
import BaseButton from '@/components/Button';

import BenefitsList from '@/pages/Benefits/BenefitsList';
import BenefitForm from '@/containers/benefits/RegisterBenefits.vue';
import { StandaloneCouponsAPI } from '@/api/StandaloneCouponsAPI';

export default {
  components: {
    BaseTitle,
    BaseEmpty,
    BaseButton,
    BenefitsList,
    BenefitForm,
    InfiniteLoading,
  },
  data() {
    return {
      pin: '',
      drawer: {
        open: false,
        close: false,
      },
      pagination: {
        page: 0,
        limit: 12,
      },
      benefits: [],
    };
  },
  mounted() {
    this.infiniteHandler();
  },
  methods: {
    async infiniteHandler($state) {
      const { pagination } = this;

      if (pagination.page === -1) {
        $state && $state.complete();
        return;
      }

      const response = await StandaloneCouponsAPI.getStandaloneCoupons(
        'ACTIVATED',
        pagination.page,
        pagination.limit
      );

      const { items, next } = response.getData({
        items: [],
        next: { page: -1, limit: 18 },
      });

      this.pagination = next;

      if (items.length > 0) {
        this.benefits.push(...items);
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }
    },
    openDrawer() {
      this.drawer.open = !this.drawer.open;
    },
    closeDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = !this.drawer.close;
    },
    changeRoute(routeName, params = {}) {
      if (params) {
        return this.$router.push({ name: routeName, params });
      }
      return this.$router.push({ name: routeName });
    },
    async save() {
      this.benefits = [];
      this.pagination = { page: 0, limit: 12 };
      this.infiniteHandler();

    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.store.isMobile,
    }),
  },
};
</script>

<style scoped></style>
