export default {
  methods: {
    buildTriggerTags(campaign) {
      const segments = [];
      const type = campaign.type || 'PN';

      if (!campaign.configs) {
        return [];
      }

      if (type) {
        if (type === 'PN') {
          if (this.isInvalidObject(campaign.configs.send_time)) {
            segments.push('Imediato');
          } else {
            segments.push('Agendado');
            segments.push(
              ...[
                campaign.configs.send_time.date.split('-').reverse().join('/'),
                campaign.configs.send_time.time,
              ]
            );
          }
        } else {
          // const recurrentMainTag = i18n.t('recurrentMainTag');
          const recurrentMainTag = 'Recorrente';
          segments.push(recurrentMainTag);
          const st = campaign.configs.send_time;
          if (
            st.all_days ||
            (st.days_week && st.days_week.length) ||
            (st.days_month && st.days_month.length)
          )
            segments.push(this.buildRecurrentTags(st));

          if (!this.isInvalidObject(campaign.configs.climate)) {
            ['temperature', 'moisture', 'rain'].forEach((field) => {
              if (campaign.configs.climate[field]) {
                segments.push(
                  this.buildRecurrentClimateTags(
                    campaign.configs.climate,
                    field
                  )
                );
              }
            });
          }

          if (!this.isInvalidObject(campaign.configs.weather))
            segments.push(
              ...this.buildRecurrentWeatherTags(campaign.configs.weather)
            );
        }
      }

      return segments;
    },

    buildFiringTags(campaign) {
      const segments = [];

      if (!campaign.configs) {
        return [];
      }

      if (campaign.configs.send_time && campaign.configs.send_time.time) {
        segments.push(`${campaign.configs.send_time.time}`);
      }

      if (campaign.configs.single_conversion) {
        segments.push(`Conversão única`);
      }

      if (campaign.configs.interval != null) {
        segments.push(
          `Intervalo de contemplação (${campaign.configs.interval})`
        );
      }

      return segments;
    },

    buildRecurrentTags(send_time) {
      if (!send_time) return null;
      if (send_time.all_days) {
        // return i18n.t('sendTime.allDays');
        return 'Todos os dias';
      }
      if (send_time.days_week && send_time.days_week.length) {
        /*
            let weekDays = [
                i18n.t('daysWeek.mon'),
                i18n.t('daysWeek.tue'),
                i18n.t('daysWeek.wed'),
                i18n.t('daysWeek.thu'),
                i18n.t('daysWeek.fri'),
                i18n.t('daysWeek.sat'),
                i18n.t('daysWeek.sun'),
            ];
            */
        const weekDays = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'];
        return weekDays
          .filter((_, index) => send_time.days_week.includes(index))
          .join(', ');
      }
      if (send_time.days_month && send_time.days_month.length) {
        return send_time.days_month.join(' - ');
      }
    },
    buildRecurrentClimateTags(climate, field) {
      const units = {
        rain: { prefix: 'Chuva', unit: '%' },
        moisture: { prefix: 'Umidade', unit: '%' },
        temperature: { prefix: 'Temperatura', unit: '°' },
      };

      const unit = units[field];

      const { min, max } = climate[field];
      return `${unit.prefix} (${min} - ${max})`;
    },
    buildRecurrentWeatherTags(weather) {
      // const transictionFunction = {
      //   SUNNY: i18n.t('SUNNY'),
      //   PARTLY_CLOUDY: i18n.t('PARTLY_CLOUDY'),
      //   CLOUDY: i18n.t('CLOUDY'),
      //   RAINING: i18n.t('RAINING'),
      // }
      const transictionFunction = {
        SUNNY: 'Ensolarado',
        PARTLY_CLOUDY: 'Parcialmente nublado',
        CLOUDY: 'Nublado',
        RAINING: 'Chovendo',
      };
      return weather.map((e) => transictionFunction[e]);
    },
    isInvalidObject(obj) {
      return !(obj && Object.keys(obj) && Object.keys(obj).length);
    },
  },
};
