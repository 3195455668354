<template>
  <div class="benefits-list">
    <div v-for="benefit in benefits" :key="benefit.id" class="benefit-card">
      <benefit-card :benefit="benefit" @openDrawer="(e) => openDrawer(e)" />
    </div>

    <qrcode
      :benefit="selectedBenefit"
      :open="drawer.open"
      :close="drawer.close"
      @closeDrawer="closeDrawer"
    />
  </div>
</template>

<script>
import Qrcode from '@/pages/Benefits/Qrcode';

import BenefitCard from '@/containers/BenefitCard';

export default {
  components: {
    Qrcode,
    BenefitCard,
  },
  props: {
    benefits: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedBenefit: null,
      drawer: {
        open: false,
        close: false,
      },
    };
  },
  methods: {
    openDrawer(e) {
      this.selectedBenefit = e;
      this.drawer.open = !this.drawer.open;
    },
    closeDrawer() {
      this.drawer.open = !this.drawer.open;
      this.drawer.close = !this.drawer.close;
    },
  },
};
</script>

<style scoped>
.benefits-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .benefits-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.benefit-card {
  background: #252a2e;
  border-radius: 10px;
  padding: 10px;
}

.qrcode-container {
  padding: 30px 20px 20px;
  background: #2d3338;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(204, 204, 204, 0.6);
  margin-top: 25px;
  text-align: center;
}

.qrcode-container p:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.qrcode-container p span.code {
  font-weight: 700;
  color: var(--default);
  text-transform: uppercase;
}

.qrcode-container p strong {
  font-weight: 700;
  color: rgba(204, 204, 204, 0.9);
}

.qrcode-container .qrcode {
  padding: 35px 20px;
  background: #32393d;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container .qrcode button {
  margin-top: 25px;
  width: 100%;
}
</style>
